import { NgModule } from '@angular/core';
import { HighlightTermPipe } from './hightlight-term.pipe';
import { HtmlPipe } from './html.pipe';
import { InitialsPipe } from './initials.pipe';
import { ObjectLengthPipe } from './object-length.pipe';
import { StripHtmlPipe } from './strip-html-pipe';
import { UrlPipe } from './url.pipe';
import { MathSignPipe } from './math-sign.pipe';

@NgModule({
  declarations: [
    HtmlPipe,
    StripHtmlPipe,
    UrlPipe,
    ObjectLengthPipe,
    HighlightTermPipe,
    InitialsPipe,
    MathSignPipe,
  ],
  exports: [
    HtmlPipe,
    StripHtmlPipe,
    UrlPipe,
    ObjectLengthPipe,
    HighlightTermPipe,
    InitialsPipe,
    MathSignPipe,
  ],
})
export class SbxPipesModule {}
