import './styles/shoobx.less';
import './globals';

// Angular 1 bootstrap
import './js/bootstrap';

// Angular 2 bootstrap
import './app/bootstrap';

const oldNg = require.context('./oldng', true, /\.js$/);
oldNg.keys().forEach(oldNg);
