import angular from 'angular';
import { List } from 'immutable';
import { DataRoomDiligenceCtrl } from '../lib/relationships/diligence';
import { DataRoomReportsCtrl } from '../lib/relationships/diligence';

/**
 * @ngdoc component
 * @name sb.lib.listpage.component:sbDataroomFolderLevelWithCheckbox
 *
 * @description
 * This component is the HTML for a single row w/ checkbox in a folder tree in the DR.
 *
 * @param {object} level The level object itself
 *   @property {string} id Unique ID of this level.
 *   @property {string} title Human readable title.
 *   @property {number} [totalDocumentCount=undefined] Number of items in this level.
 *   @property {boolean} [disabled=false] Is level disabled/does not show count
 * @param {boolean} isOpen If level is displayed as open.
 * @param {boolean} [hasChildren=false] If level has children.
 * @param {number} [indent=0] Indentation factor.
 * @param {expression} [toggle=undefined] Expression is evalutated when icon is clicked.
 *   `$event` will be in the namespace.
 */
export const sbDataroomFolderLevelWithCheckbox = {
  template: require('./templates/folder-level-with-checkbox.html'),
  controllerAs: 'vm',
  transclude: {
    icon: '?icon',
    itemtitle: '?itemtitle',
  },
  bindings: {
    level: '<',
    isOpen: '<',
    isChecked: '<',
    hasChildren: '<?',
    indent: '<?',
    toggle: '&?',
  },
  controller: [
    function () {
      this.$onInit = () => {
        this.borderStyles = { 'margin-right': (this.indent || 0) * 14 + 'px' };
      };
    },
  ],
}; // end sbDataroomFolderLevelWithCheckbox

/**
 * @ngdoc component
 * @name sb.lib.listpage.compoent:sbDataroomFolderLevel
 *
 * @description
 * This component is the HTML for a single row in a folder tree in the DR.
 *
 * @param {object} level The level object itself
 *   @property {string} id Unique ID of this level.
 *   @property {string} title Human readable title.
 *   @property {number} [totalDocumentCount=undefined] Number of items in this level.
 *   @property {boolean} [disabled=false] Is level disabled/does not show count
 * @param {boolean} isOpen If level is displayed as open.
 * @param {boolean} [hasChildren=false] If level has children.
 * @param {number} [indent=0] Indentation factor.
 * @param {expression} [toggle=undefined] Expression is evalutated when icon is clicked.
 *   `$event` will be in the namespace.
 */
export const sbDataroomFolderLevel = {
  template: require('./templates/dr-folder-level.html'),
  controllerAs: 'vm',
  transclude: {
    icon: '?icon',
    itemtitle: '?itemtitle',
  },
  bindings: {
    level: '<',
    isOpen: '<',
    hasChildren: '<?',
    indent: '<?',
    toggle: '&?',
  },
  controller: [
    '$scope',
    function ($scope) {
      this.$onInit = () => {
        this.borderStyles = { 'margin-right': (this.indent || 0) * 14 + 'px' };
      };

      $scope.$on('sbFolderUpdated', (ent, args) => {
        if (this.level.id === args.folderId) {
          this.level.totalDocumentCount = args.total;
        }
      });
    },
  ],
}; // end sbDataroomFolderLevel

/**
 * @ngdoc component
 * @name sb.lib.listpage.compoent:sbDataroomFolderTree
 *
 * @description
 * This component is the HTML for the entire folder tree.
 *
 * @param {array<folderObject>} folders The sorted lists of folders.
 *   @property {string} id Unique ID of this level.
 *   @property {string|null} parent Unique ID of this level's parent.
 *   @property {string} title Human readable title.
 *   @property {number} [totalDocumentCount=undefined] Number of items in this level.
 *   @property {boolean} [disabled=false] Is level disabled/does not show count
 * @param {expression} ngModel Model spression with string selected ID.
 */
export const sbDataroomFolderTree = {
  template: require('./templates/dr-folder-tree.html'),
  controllerAs: 'vm',
  transclude: true,
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    folders: '<',
    model: '=ngModel',
  },
  controller: [
    function () {
      function select(level, $isOpen, $toggleLevel) {
        if ($isOpen && !$isOpen(level)) {
          $toggleLevel(level);
        }
        this.ngModelCtrl.$setViewValue(level.id);
      }
      this.$onInit = () => {
        this.topLevel = this.folders[0];
        this.mainLevels = this.folders.slice(1);
        this.model = this.model || this.topLevel.id;
        this.select = select.bind(this);
      };
    },
  ],
}; // end sbDataroomFolderTree

const MAPPER = (data, requiredFilterValues) => {
  const updateData = angular.extend({}, requiredFilterValues);
  angular.forEach(data, (value, key) => {
    const filterValue = updateData[key];

    if ((!value || value.length === 0) && filterValue) {
      return;
    }

    if (
      key === 'required_tags' &&
      angular.isArray(filterValue) &&
      angular.isArray(value)
    ) {
      updateData[key] = filterValue.concat(value);
    } else {
      updateData[key] = value;
    }
  });
  return updateData;
};

const METADATA_FORMATTER = (item) => {
  const metaData = angular.copy(item.prominent_metadata);
  const firstHalf = metaData.slice(0, 4);
  const secondHalf = metaData.slice(4, -1);
  // eslint-disable-next-line camelcase
  firstHalf.splice(0, 0, { value_html: item.status, label: 'Status' });
  return secondHalf.length ? [firstHalf, secondHalf] : [firstHalf];
};

/**
 * @ngdoc object
 * @name sb.listpage.DataRoomPageModel
 * The model for dataroom listpage.  Extends ListPageModel a bit.
 */
export const DataRoomPageModel = [
  'ListPageModel',
  function (ListPageModel) {
    const model = angular.extend({}, ListPageModel);

    model.addBreadcrumbsToItems = function () {
      this.items.forEach((item) => {
        if (this.filter.query && item.folders && item.folders.length) {
          const folders = item.folders[0];
          const id = folders[folders.length - 1].id;
          if (id && id !== this.folder.id) {
            item.breadcrumbs = folders.slice(1, folders.length);
          }
        } else {
          item.breadcrumbs = null;
        }
      });
    };

    model.onSuccess = function (data) {
      const { response } = data;
      const { items, folder, batch } = response;
      if (this.appending) {
        this.items = this.items.concat(List(items));
        // Filter out dupes by id -- defensive, should not happen unless new docs
        // appear between page loads.
        this.items = this.items
          .groupBy((i) => i.id)
          .map((g) => g.first())
          .toList();
        this.folder = folder || {};
        if (batch) {
          const { total } = batch;
          this.paging.totalItems = total;
        }
      } else {
        ListPageModel.onSuccess.call(model, data);
        this.loadFolderData(response);
      }
    };

    model.loadFolderData = function ({
      rootFolder,
      folderPath,
      folder,
      subfolders,
      showing,
      batch,
    }) {
      if (rootFolder) {
        this.rootFolder = rootFolder;
      }
      this.folder = folder || this.rootFolder;

      this.folderPath =
        folderPath && folderPath.length ? List(folderPath) : [this.rootFolder];

      this.subfolders = List(subfolders);
      this.subfolders.forEach((item) => {
        const { path, parent } = item;
        if (path && this.folder && parent !== this.folder.id) {
          item.breadcrumbs = path.slice(1, -1);
        }
      });

      model.addBreadcrumbsToItems();

      this.showing = '0';
      if (showing) {
        // When hydrating
        this.showing = showing;
      } else if (batch) {
        // When updating
        this.showing = batch.showing;
      }
    };

    model.openFolder = function (folder) {
      if (this.filter.query) {
        this.filter.query = '';
      }

      this.filter.folder = folder.id;
      this.folder = folder;
      this.setFilter();
    };

    model.data = function () {
      const data = angular.copy(this.filter);
      data.size = this.size;
      data.page = this.paging.page;

      if (angular.isUndefined(data.tag)) {
        data.tag = [];
      }

      // Root folder gets special filter behavior
      if (this.rootFolder.id === data.folder) {
        const haveTags = data.tag && data.tag.length > 0;
        const haveQuery = data.query;
        if (haveQuery || haveTags) {
          data.globalSearch = true;
        }
      }

      if (!data.query && (data.tag === null || data.tag.length === 0) && !data.folder) {
        data.folder = this.rootFolder.id;
      }

      angular.forEach(this.sort, (value, key) => {
        if (angular.isUndefined(data[key])) {
          data[key] = value;
        }
      });

      return this.mapper(data, this.requiredFilterValues);
    };

    model.isPagingVisible = () => {
      return false; // Endless scroll for DR NG, hide paging controls
    };

    model.isTopSortingEnabled = false;
    model.size = 20;
    model.appending = false;

    model.loadMore = function () {
      this.appending = true;
      const limit = {
        page: Math.floor(this.items.size / this.size),
        size: this.size,
      };
      this.update(this.data(), {}, limit).then(
        () => {
          this.appending = false;
          this.paging.page = 0;

          model.addBreadcrumbsToItems();
        },
        () => {
          this.appending = false;
          this.paging.page = 0;
          this.status = 'Loading failed.';
        },
      );
    };

    const _isLoading = model.isLoading.bind(model);
    model.isLoading = function () {
      // Don't show the main Loading spinner overlay when appending
      return _isLoading() && !this.appending;
    };

    return model;
  },
];

/**
 * @ngdoc object
 * @kind function
 * @name sb.listpage.object:$dataroomIndexModal
 *
 * @description
 * This is a high level function for opening a dataroom index modal.
 *
 * @param {string} dataroomOnEntity The entity that the index will be built on.
 * @param {string} dataroomForSh stakeholder that the index will be built upon.
 * @param {string} teamRelationshipType required when exporting index for a team.
 * @param {string} email The email of the user that will be displayed in modal.
 *
 * @returns {Promise} Resolves when the index is emailed, rejects otherwise (dismiss).
 */
export const $dataroomIndexModal = [
  '$formModal',
  'BackendLocation',
  'SimpleHTTPWrapper',
  function ($formModal, BackendLocation, SimpleHTTPWrapper) {
    const URL = `${BackendLocation.entity(1)}documents/downloadindex`;
    const VOCAB = [
      { label: 'All Entries', value: 'all' },
      { label: 'Only documents added since', value: 'since' },
    ];
    const TO = { required: true, enumVocab: VOCAB };
    const INDEX_FIELDS = [
      { data: {}, key: 'inclusion', type: 'enum-radios', templateOptions: TO },
      {
        data: {},
        key: 'sinceDate',
        type: 'date',
        hideExpression: "model.inclusion !== 'since'",
        templateOptions: { required: true, subfield: 1, label: 'Date' },
      },
    ];
    return (
      email,
      dataroomOnEntity = null,
      dataroomForSh = null,
      teamRelationshipType = null,
    ) => {
      let loading = false;

      const myPromise = new Promise((resolve) => {
        if (dataroomForSh && teamRelationshipType) {
          SimpleHTTPWrapper(
            {
              method: 'GET',
              url: `${BackendLocation.entity(1)}stakeholders/${dataroomForSh}`,
              params: {},
            },
            'Could not receive stakeholder information',
          ).then((data) => resolve(data.title));
        } else {
          resolve(null);
        }
      });
      return myPromise.then((shTitle) =>
        $formModal({
          htmlContent: require('./templates/index-modal.html'),
          title: 'Export Index',
          forms: { dataroomIndexForm: { fields: INDEX_FIELDS } },
          formData: { dataroomIndexForm: { inclusion: 'all' } },
          extraInfo: { email, shTitle },
          loading: () => loading,
          primaryButtonText: 'Export Index',
          onConfirmPromise: ({ $formData: { dataroomIndexForm: model } }) => {
            const addedAfter = model.inclusion === 'since' ? model.sinceDate : null;
            loading = true;
            return SimpleHTTPWrapper(
              {
                method: 'POST',
                url: URL,
                data: {
                  addedAfter,
                  dataroomOnEntity,
                  dataroomForSh,
                  teamRelationshipType,
                },
              },
              'Could not export index.',
            ).finally(() => {
              loading = false;
            });
          },
        }),
      );
    };
  },
]; // end $dataroomIndexModal

/**
 * @ngdoc object
 * @kind function
 * @name sb.listpage.object:$exportDataRoomIndexModal
 *
 * @description
 * This is a high level function for opening a export dataroom documents index modal.
 *
 * @param filter: data for filtering request
 * @param rootFolder: main directory id
 * @param action: action
 *
 * @returns {Promise} Resolves when the download url is emailed, rejects otherwise (dismiss).
 */
export const $exportDataRoomIndexModal = [
  '$formModal',
  'BackendLocation',
  'SimpleHTTPWrapper',
  function ($formModal, BackendLocation, SimpleHTTPWrapper) {
    const URL = `${BackendLocation.entity(1)}documents/archive`;
    const VOCAB = [
      { label: 'All documents', value: 'all' },
      {
        label: 'Documents in the current folder and sub-folders only',
        value: 'current-dir',
      },
    ];
    const TO = { required: true, enumVocab: VOCAB };
    const INDEX_FIELDS = [
      { data: {}, key: 'inclusion', type: 'enum-radios', templateOptions: TO },
    ];
    return (filter, rootFolder, action) => {
      let loading = false;

      const myPromise = new Promise((resolve) => {
        SimpleHTTPWrapper({
          url: BackendLocation.entity(1) + 'async-tasks/for_object',
          // eslint-disable-next-line camelcase
          params: { object_id: action.objectId },
          method: 'GET',
        }).then((data) => resolve(data.title));
      });
      return myPromise.then(() =>
        $formModal({
          htmlContent: require('./templates/export-data-room-modal.html'),
          title: 'Download Zip Archive',
          forms: { exportDataRoomIndexForm: { fields: INDEX_FIELDS } },
          formData: { exportDataRoomIndexForm: { inclusion: 'current-dir' } },
          loading: () => loading,
          primaryButtonText: 'Download',
          onConfirmPromise: ({ $formData: { exportDataRoomIndexForm: model } }) => {
            if (model.inclusion === 'all') {
              filter.folder = rootFolder;
            }
            loading = true;
            return SimpleHTTPWrapper(
              {
                method: 'POST',
                url: URL,
                data: filter,
              },
              'Could not export selected documents.',
            ).finally(() => {
              loading = false;
            });
          },
        }),
      );
    };
  },
]; // end $exportDataRoomIndexModal

function manageConfirmOptions(folder) {
  const suggestionListing = `<ul><li>${folder.protectedProcessSuggestions.join(
    '</li><li>',
  )}</li></ul>`;
  return {
    alertType: 'warning',
    title: 'Upload Documents',
    confirmButtonText: 'Continue',
    body: `
      <p>
        You have selected to upload to a folder that is managed by Fidelity Private Shares. To fully
        maximize the value of Fidelity Private Shares, we ask you to use one of the workflows listed
        below to capture some additional information about the documents in
        <strong>${folder.title}</strong> folder.
      </p>
      ${suggestionListing}
    `,
  };
} // end manageConfirmOptions

function uploadDocumentModal($sbModal, model) {
  const { folder } = model;
  const templateOptions = { uploadURL: `folders/${folder.id}/upload_unmanaged` };
  return $sbModal.open({
    controllerAs: 'vm',
    size: 'lg',
    bindToController: true,
    windowClass: 'upload-document-modal',
    keyboard: false,
    backdrop: 'static',
    template: require('./templates/upload-document-modal.html'),
    controller: function () {
      this.folder = folder;
      this.outstanding = false;
      this.templateOptions = templateOptions;
      this.model = model;
    },
  }).result;
} // end uploadDocumentModal

/**
 * @ngdoc directive
 * @name sb.listpage.sbDataRoomPage
 *
 * @param {object} sbFilters The form description of the filter form.
 * @param {object} sbSorts The form description of the sort form.
 * @param {string} sbRedirect The URL for the back to dashboard button.
 * @param {string} sbRedirectTitle The text for the back to dashboard button.
 * @param {object} sbPaging The paging model, an object like
 *     `{ page: 1, totalPages: 200 }`.
 * @param {number} sbShowing The number of visible documents in the current folder
 *     and subfolders, displayed in the "Showing X out of Y" text on the page.
 * @param {number} sbTotalSize The total number of documents available to the
 *     user in the Data Room.  Y in "Showing X out of Y".
 * @param {number} sbSize The number of documents loaded at a time.
 **/
export const sbDataRoomPage = [
  'BackendLocation',
  'ListLocation',
  function (BackendLocation, ListLocation) {
    return {
      restrict: 'E',
      template: require('./templates/dataroom.html'),
      scope: {
        title: '@sbTitle',
        apiPath: '<sbApiPath',
        showActions: '<sbShowActions',
        totalSize: '<sbTotalSize',
        filterForms: '<sbFilters',
        sortForms: '<sbSorts',
        actions: '<sbActions',
        redirect: '@sbRedirect',
        redirectTitle: '@sbRedirectTitle',
        filterData: '<sbFilterData',
        sortData: '<sbSortData',
        requiredFilterValues: '<sbRequiredFilterValues',
        size: '<sbSize',
        sort: '<sbSort',
        folder: '<sbFolder',
        paging: '<sbPaging',
        showing: '<sbShowing',
        items: '<sbItems',
        subfolders: '<sbSubfolders',
        folderPath: '<sbFolderPath',
        rootFolder: '<?sbRootFolder',
        errorMessage: '@?sbErrorMessage',
        dataroomUrl: '<sbDataroomUrl',
      },
      controller: [
        '$q',
        '$scope',
        '$location',
        '$window',
        '$confirm',
        'SimpleHTTPWrapper',
        '$editDocumentPropertiesModal',
        'PromiseErrorCatcher',
        '$exportDataRoomIndexModal',
        '$dataroomIndexModal',
        '$sbModal',
        'AppConfig',
        '$trashModal',
        'TextModelOptions',
        'DataRoomPageModel',
        '$timeout',
        function (
          $q,
          $scope,
          $location,
          $window,
          $confirm,
          SimpleHTTPWrapper,
          $editDocumentPropertiesModal,
          PromiseErrorCatcher,
          $exportDataRoomIndexModal,
          $dataroomIndexModal,
          $sbModal,
          AppConfig,
          $trashModal,
          TextModelOptions,
          DataRoomPageModel,
          $timeout,
        ) {
          function doHTTPThenRefresh(httpOptions, defaultError) {
            $scope.status = null;
            return SimpleHTTPWrapper(httpOptions, defaultError)
              .catch((error) =>
                $q.reject(angular.isString(error) ? error : defaultError),
              )
              .then(() => $scope.model.refreshWithCurrentParams())
              .then(() => {
                $scope.$broadcast('sbFolderUpdated', {
                  folderId: $scope.model.folder.id,
                  total: $scope.model.showing,
                });
              })
              .catch((error) => {
                const reason = angular.isString(error)
                  ? error
                  : 'Failed to load results.';
                $scope.status = { type: 'danger', reason };
              });
          }
          $scope.showTrash = () => {
            $trashModal()
              .then(() => $scope.model.refreshWithCurrentParams())
              .catch(PromiseErrorCatcher);
          };

          const refreshReturnUrl = (url) => {
            $scope.returnUrl = btoa(url + '^^^' + $scope.title);
          };
          refreshReturnUrl($location.absUrl());
          $scope.$watch(() => $location.absUrl(), refreshReturnUrl);

          $scope.model = DataRoomPageModel;
          $scope.prominentMDLists = METADATA_FORMATTER;
          $scope.refreshModel = () => {
            $scope.reload();
          };
          $scope.documentURL = (item) => {
            return (
              item.href.ui +
              '?folder=' +
              $scope.folder.id +
              '&return_to=' +
              $scope.returnUrl
            );
          };
          $scope.trashFolder = (evt, folder, withinFolder) => {
            evt.preventDefault();
            SimpleHTTPWrapper({
              url: `${$scope.FOLDER_BASE_URL}${folder.id}/trash`,
              method: 'POST',
            })
              .then(async () => {
                if (withinFolder) {
                  const parentFolder = $scope.folderPath.find(
                    (item) => item.id === folder.parent,
                  );
                  await $scope.model.openFolder(parentFolder);
                }
                $scope.reload();
              })
              .catch(PromiseErrorCatcher);
          };
          $scope.trashDocument = (evt, doc) => {
            evt.preventDefault();
            doHTTPThenRefresh({
              url: `${$scope.DOCUMENT_BASE_URL}${doc.id}/trash`,
              method: 'POST',
            });
          };
          $scope.uploadDocumentsToFolder = () => {
            const { model } = $scope;
            const { folder } = model;
            const { protectedProcessSuggestions: protec } = folder;
            const initProm =
              protec && protec.length
                ? $confirm(manageConfirmOptions(folder))
                : $q.resolve();
            initProm
              .then(() => uploadDocumentModal($sbModal, model))
              .then(() => $scope.model.refreshWithCurrentParams())
              .then(() => {
                $scope.$broadcast('sbFolderUpdated', {
                  folderId: $scope.model.folder.id,
                  total: $scope.model.showing,
                });
              })
              .catch(PromiseErrorCatcher);
          };
          $scope.editDocumentProperties = (document) => {
            $editDocumentPropertiesModal(document)
              .then(() => $scope.model.refreshWithCurrentParams())
              .catch(PromiseErrorCatcher);
          };

          $scope.reload = () => {
            // TODO: We are reloading page, because folder tree navigation is not
            // updated when we add / remove folder, etc. This reload functionality
            // causes bad UX and we should fix it.
            location.reload();
          };

          $scope.handleAction = (evt, action) => {
            if (action.link === './archive') {
              evt.preventDefault();
              const filter = $scope.model.data();
              const rootFolder = $scope.rootFolder.id;
              $exportDataRoomIndexModal(filter, rootFolder, action)
                .then(() => {
                  const reason =
                    'An archive of the documents you requested is ' +
                    'being prepared, and a link will be sent to ' +
                    'you shortly via email.';
                  $scope.status = { type: 'success', reason };
                })
                .catch(PromiseErrorCatcher);
              $timeout(() => {
                angular.element('body').click();
              });
            }
            if (action.link === './DataRoomIndex') {
              evt.preventDefault();
              const email =
                AppConfig.userProfile.email || AppConfig.userProfile.contactEmail;
              $dataroomIndexModal(email, null, AppConfig.userProfile.id, null)
                .then(() => {
                  const reason = 'A link to your index will be sent to you shortly.';
                  $scope.status = { type: 'success', reason };
                })
                .catch(PromiseErrorCatcher);
              return;
            }
            if (action.link === './diligence') {
              evt.preventDefault();
              manageDiligence($sbModal, $scope, PromiseErrorCatcher);
            }
            if (action.link === './diligence-reports') {
              evt.preventDefault();
              diligenceReports(
                $sbModal,
                $scope,
                BackendLocation,
                SimpleHTTPWrapper,
                PromiseErrorCatcher,
              );
            }
          };

          if ($scope.actions) {
            $scope.actions.forEach((action) => {
              action.click = function (evt) {
                if (action.link) {
                  $scope.handleAction(evt, action);
                }
              };
            });
          }

          $scope.DOCUMENT_BASE_URL = BackendLocation.entity(1) + 'documents/';
          $scope.FOLDER_BASE_URL = BackendLocation.entity(1) + 'folders/';

          const updateUrl = (data) => {
            ListLocation.updateUrl(data);
          };

          // copied from sbFilteredListFilter
          $scope.fieldLabels = {};
          $scope.searchOptions = TextModelOptions;
          $scope.hasFilters = () => {
            let filters = false;
            angular.forEach($scope.model.filter, (value, key) => {
              if ((value && value.length === 0) || key === 'folder') {
                // Ignore empty arrays (tags) and the folder filter.
                return;
              }
              filters = filters || Boolean(value);
            });
            return filters;
          };

          // copied from sbFilteredListFilter
          $scope.clearFilter = (fields) => {
            const folder = $scope.model.filter.folder;
            if (fields) {
              if (!angular.isArray(fields)) {
                fields = [fields];
              }

              for (const field of fields) {
                $scope.model.filter[field] = null;
              }
            } else {
              $scope.model.filter = {};
            }

            if (folder) {
              $scope.model.filter.folder = folder;
            }
            DataRoomPageModel.setFilter();
          };

          $scope.empty = () => {
            return $scope.model.items.size + $scope.model.subfolders.size === 0;
          };

          $scope.reload = () => {
            $window.location.reload();
          };

          const defaultFolder = { folder: ($scope.folder || {}).id };
          $scope.model.initialize(
            $scope.filterData,
            angular.extend({}, $scope.requiredFilterValues, defaultFolder),
            $scope.size,
            $scope.sortData,
            $scope.paging,
            $scope.items,
            $scope.errorMessage,
            {
              url: BackendLocation.entity(1) + $scope.apiPath,
              method: 'POST',
            },
            updateUrl,
            MAPPER,
          );
          $scope.model.loadFolderData($scope);

          const drConverters = {
            tree: (key, allData) => ({ [key]: allData[key] }),
          };

          ListLocation.updateModelOnLocationChange($scope, drConverters);
        },
      ],
    };
  },
];

function manageDiligence($sbModal, $scope, PromiseErrorCatcher) {
  return $sbModal
    .open({
      backdrop: 'static',
      size: 'lg',
      windowClass: 'form-modal',
      bindToController: true,
      controllerAs: 'vm',
      template: require('./templates/multi-step-diligence-modal.html'),
      controller: DataRoomDiligenceCtrl,
    })
    .result.then(() => {
      const reason = 'Diligence settings saved.';
      $scope.status = { type: 'success', reason };
    })
    .catch(PromiseErrorCatcher);
}

function diligenceReports($sbModal, $scope, PromiseErrorCatcher) {
  $scope.loading = true;
  return $sbModal
    .open({
      backdrop: 'static',
      size: 'lg',
      windowClass: 'form-modal',
      bindToController: true,
      controllerAs: 'vm',
      template: require('./templates/diligence-reports.html'),
      controller: DataRoomReportsCtrl,
    })
    .result.catch(PromiseErrorCatcher);
}
