import angular from 'angular';
import { Map, List } from 'immutable';

/**
 * @ngdoc directive
 * @name sb.onboarding.sbHrTicketsAdmin
 *
 * This is a directive that creates a Tickets Admin Page.
 * @param {string} type Type of tickets, e.g. 'onboarding' or 'offboarding'.
 *
 */
export const sbHrTicketsAdmin = {
  template: require('./templates/hr-tickets-admin.html'),
  controllerAs: 'vm',
  bindings: {
    type: '@',
  },
  controller: [
    '$scope',
    '$q',
    'SimpleHTTPWrapper',
    'BackendLocation',
    function ($scope, $q, SimpleHTTPWrapper, BackendLocation) {
      const vm = this;
      this.$onChanges = () => {
        function updateModel() {
          const duedateVocab = $scope.ticketsFormDesc.fields.find(
            (item) => item.key === 'deadline',
          ).templateOptions.enumVocab;

          const indexProfiles = (profiles) => {
            if (!profiles) {
              return Map({});
            }
            const idx = {};
            for (const prf of profiles) {
              idx[prf.id] = prf;
            }
            return Map(idx);
          };

          const assigneeList = (ticket) => {
            const stakeholders = (ticket.assigned_stakeholders || []).map((obj) => {
              return obj ? obj.sh.fullName : undefined;
            });
            if (ticket.supervisor_assigned) {
              stakeholders.push('Manager');
            }
            return stakeholders.join(', ');
          };

          const profileList = (profiles, prfIndex) => {
            const titles = (profiles || []).map((prfId) => {
              return prfIndex.get(prfId) ? prfIndex.get(prfId).title : '';
            });
            if (titles.length === 0) {
              return 'All Employees';
            }

            return titles.join(', ');
          };

          const deadlineFormat = (deadline) => {
            const enumVocab = duedateVocab.find((item) => item.value === deadline);
            return enumVocab ? enumVocab.label : deadline;
          };

          const mapTicketsToItems = (tickets, profiles) => {
            return List(
              tickets.map((ticket, index) => {
                return Map({
                  id: index,
                  deletable: true,
                  editable: true,
                  editTitle: 'Edit Onboarding Task',
                  data: Map(
                    angular.extend(
                      {
                        assigneesColumn: Map({ fmtValue: assigneeList(ticket) }),
                        dueDateColumn: Map({
                          fmtValue: deadlineFormat(ticket.deadline),
                        }),
                        descColumn: Map({ fmtValue: ticket.description }),
                        nameColumn: Map({ fmtValue: ticket.name }),
                        employeeGroupsColumn: Map({
                          fmtValue: profileList(
                            ticket.employee_groups || ticket.newhire_groups,
                            profiles,
                          ),
                        }),
                      },
                      ticket,
                    ),
                  ),
                });
              }),
            );
          };

          const profiles = indexProfiles($scope.profiles);
          $scope.tasksModel = {
            $items: mapTicketsToItems($scope.tickets, profiles),
            $hasEditOrDelete: true,
            $fetchItems: (tickets) => {
              return $scope.$saveTickets(tickets).then(() => {
                const profiles = indexProfiles($scope.profiles);
                $scope.tasksModel.$items = mapTicketsToItems($scope.tickets, profiles);
              });
            },
            $add: (data) => {
              const tickets = angular.copy($scope.tickets);
              tickets.push(data);
              return $scope.tasksModel.$fetchItems(tickets);
            },
            $remove: (index) => {
              const tickets = angular.copy($scope.tickets);
              tickets.splice(index, 1);
              $scope.tasksModel.$loading = true;
              return $scope.tasksModel.$fetchItems(tickets);
            },
            $edit: (id, data) => {
              const tickets = angular.copy($scope.tickets);
              tickets[id] = data;
              return $scope.tasksModel.$fetchItems(tickets);
            },
            $loading: false,
          };
        }
        $scope.columns = [
          { key: 'nameColumn', name: 'Name' },
          { key: 'assigneesColumn', name: 'Assignee' },
          { key: 'dueDateColumn', name: 'Due' },
          { key: 'descColumn', name: 'Description' },
          { key: 'employeeGroupsColumn', name: 'Groups' },
        ];

        $scope.tasksModel = {
          $loading: false,
        };

        $scope.$loadTickets = () => {
          $scope.tasksModel.$loading = true;
          return SimpleHTTPWrapper({
            method: 'GET',
            url: BackendLocation.entity(1) + 'ticket-templates?type=' + vm.type,
          }).then((data) => {
            $scope.tasksModel.$loading = false;
            $scope.tickets = data.tickets;
            $scope.profiles = data.profiles;
            $scope.ticketsFormDesc = data.form;
            updateModel();
            return true;
          });
        };

        $scope.$saveTickets = (tickets) => {
          $scope.tasksModel.$loading = true;
          return SimpleHTTPWrapper({
            method: 'POST',
            url: BackendLocation.entity(1) + 'ticket-templates',
            data: {
              type: vm.type,
              tickets: tickets,
            },
          }).then(
            (data) => {
              $scope.tasksModel.$loading = false;
              $scope.tickets = data.tickets;
              $scope.profiles = data.profiles;
              $scope.ticketsFormDesc = data.form;
              updateModel();
              return true;
            },
            (errors) => {
              $scope.tasksModel.$loading = false;
              if (angular.isArray(errors) && errors.length) {
                errors = errors[0];
              }
              return $q.reject(errors);
            },
          );
        };

        $scope.$loadTickets();
      };
    },
  ],
};

/**
 * @ngdoc directive
 * @name sb.onboarding.sbHrTicketsPage
 *
 * This is a directive that creates the "My Tickets" Page.
 *
 * Most parameters pass through to the sbFilteredListPage, so look there for
 * what the paramters are for.
 *
 * @param {string} type Type of tickets, e.g. 'onboarding' or 'offboarding'.
 */
export const sbHrTicketsPage = [
  'ListPageModel',
  'ListLocation',
  'BackendLocation',
  function (ListPageModel, ListLocation, BackendLocation) {
    return {
      restrict: 'E',
      template: require('./templates/hr-tickets-page.html'),
      scope: {
        title: '@sbTitle',
        redirect: '@sbRedirect',
        totalSize: '<sbTotalSize',
        filterForms: '<sbFilters',
        sortForms: '<sbSorts',
        actions: '<sbActions',
        filterData: '<sbFilterData',
        sortData: '<sbSortData',
        requiredFilterValues: '<sbRequiredFilterValues',
        size: '<sbSize',
        sort: '<sbSort',
        paging: '<sbPaging',
        items: '<sbTickets',
        type: '@',
      },
      controller: [
        '$scope',
        'PromiseErrorCatcher',
        function ($scope, PromiseErrorCatcher) {
          $scope.model = ListPageModel;
          $scope.refreshModel = () => {
            $scope.model.refreshWithCurrentParams().catch(PromiseErrorCatcher);
          };
          $scope.model.initialize(
            $scope.filterData,
            $scope.requiredFilterValues,
            $scope.size,
            $scope.sortData,
            $scope.paging,
            $scope.items,
            $scope.errorMessage,
            {
              url: BackendLocation.entity(1) + 'tickets/my_hr_tickets',
              method: 'POST',
            },
            ListLocation.updateUrl,
          );
          $scope.model.hidePaging = true;
          ListLocation.updateModelOnLocationChange($scope);
        },
      ],
    };
  },
];

/**
 * @ngdoc component
 * @name sb.onboarding.sbHrCompleteButton
 *
 * @description
 * A button that brings up a modal that allows to complete a task.
 */
export const sbHrCompleteButton = {
  controllerAs: 'vm',
  template: `<button class="btn btn-int" id="complete-{{::vm.task.id}}"
                     ng-click="vm.completeModal()">
              <i class="far fa-check-circle"></i> Complete
            </button>`,
  bindings: {
    task: '=sbTask',
    onClose: '&',
  },
  controller: [
    '$scope',
    '$sbModal',
    'PromiseErrorCatcher',
    function ($scope, $sbModal, PromiseErrorCatcher) {
      function completeModal() {
        $sbModal
          .open({
            bindToController: true,
            size: 'lg',
            controllerAs: 'vm',
            template: require('./templates/complete-modal.html'),
            resolve: {
              Task: () => this.task,
              OnClose: () => this.onClose,
            },
            controller: CompleteModalCtrl,
          })
          .result.catch(PromiseErrorCatcher);
      }

      this.$onInit = () => {
        this.completeModal = completeModal.bind(this);
      };
    },
  ],
};

const CompleteModalCtrl = [
  'Task',
  'OnClose',
  'TicketsService',
  function (Task, OnClose, TicketsService) {
    function completeTask() {
      return TicketsService.complete(Task.id).then((data) => {
        this.$close();
        OnClose();
        return data;
      });
    }

    this.completeTask = completeTask.bind(this);
    this.task = Task;
  },
];

export const sbHrTasksReport = [
  'ListPageModel',
  'ListLocation',
  'BackendLocation',
  function (ListPageModel, ListLocation, BackendLocation) {
    return {
      restrict: 'E',
      template: require('./templates/hr-tasks-report.html'),
      scope: {
        title: '@sbTitle',
        redirect: '@sbRedirect',
        totalSize: '<sbTotalSize',
        filterForms: '<sbFilters',
        sortForms: '<sbSorts',
        actions: '<sbActions',
        filterData: '<sbFilterData',
        sortData: '<sbSortData',
        requiredFilterValues: '<sbRequiredFilterValues',
        size: '<sbSize',
        sort: '<sbSort',
        paging: '<sbPaging',
        items: '<sbItems',
        type: '@',
      },
      controller: [
        '$scope',
        'PromiseErrorCatcher',
        'SbxFormModalService',
        function ($scope, PromiseErrorCatcher, SbxFormModalService) {
          $scope.model = ListPageModel;
          $scope.model.initialize(
            $scope.filterData,
            $scope.requiredFilterValues,
            $scope.size,
            $scope.sortData,
            $scope.paging,
            $scope.items,
            $scope.errorMessage,
            {
              url: BackendLocation.entity(1) + 'tickets/hr_report',
              method: 'POST',
            },
            ListLocation.updateUrl,
          );
          $scope.model.hidePaging = true;
          ListLocation.updateModelOnLocationChange($scope);

          $scope.refreshModel = () => {
            $scope.model.refreshWithCurrentParams().catch(PromiseErrorCatcher);
          };
          $scope.setStatus = (msg) => {
            $scope.status = msg;
          };
          $scope.add = () => {
            const modal = SbxFormModalService.open({ data: { url: 'tickets/add' } });
            modal.result.then($scope.refreshModel).catch(PromiseErrorCatcher);
          };
        },
      ],
    };
  },
];

/**
 * @ngdoc component
 * @name sb.onboarding.sbOnboardingTaskReportMenu
 *
 * @description
 * A dropdown menu of task actions.
 */
export const sbOnboardingTaskReportMenu = {
  controllerAs: 'vm',
  template: require('./templates/task-report-menu.html'),
  bindings: {
    task: '=sbTask',
    onSuccess: '&',
  },
  controller: [
    '$scope',
    'TicketsService',
    'SbxFormModalService',
    'PromiseErrorCatcher',
    'BackendLocation',
    '$confirm',
    function (
      $scope,
      TicketsService,
      SbxFormModalService,
      PromiseErrorCatcher,
      BackendLocation,
      $confirm,
    ) {
      function complete() {
        return TicketsService.complete(this.task.id).then((result) => {
          this.onSuccess();
          this.isMenuOpen = false;
          return result;
        });
      }

      function remind() {
        return TicketsService.remind(this.task.id).then((result) => {
          this.isMenuOpen = false;
          this.onSuccess({ msg: 'Reminder sent!' });
          return result;
        });
      }

      function edit() {
        const modal = SbxFormModalService.open({
          data: {
            url: `tickets/${this.task.id}/edit`,
          },
        });
        modal.result.then(this.onSuccess).catch(PromiseErrorCatcher);
      }

      function remove() {
        $confirm({
          title: 'Delete Task',
          body: 'Are you sure you want to delete this task?',
          confirmButtonText: 'Delete',
        })
          .then(() => {
            return TicketsService.remove(this.task.id).then((result) => {
              this.isMenuOpen = false;
              this.onSuccess({ msg: 'Task deleted!' });
              return result;
            });
          })
          .catch(PromiseErrorCatcher);
      }

      this.$onInit = () => {
        this.isMenuOpen = false;
        this.complete = complete.bind(this);
        this.remind = remind.bind(this);
        this.edit = edit.bind(this);
        this.remove = remove.bind(this);
      };
    },
  ],
};

/**
 * @ngdoc object
 * @kind function
 * @name sb.onboarding.OnboardingTemplatesModel
 *
 * @description
 * This factory function produces a new onboarding templates model that
 * maintains a list of template HR onboarding forms.
 *
 * @returns {object} The model. See below for property/method documentation.
 */
export const OnboardingTemplatesModel = [
  '$q',
  'SimpleHTTPWrapper',
  'BackendLocation',
  function ($q, SimpleHTTPWrapper, BackendLocation) {
    const FORMS_API_URL = BackendLocation.entity(1) + 'pdfforms/';
    return () => ({
      /**
       * @ngdoc property
       * @name templates
       * @propertyOf sb.onboarding.OnboardingTemplatesModel
       *
       * @description
       * An immutable list of template objects.
       */
      templates: List(),

      /**
       * @ngdoc property
       * @name loading
       * @propertyOf sb.onboarding.OnboardingTemplatesModel
       *
       * @description
       * A boolean of outstanding async.
       */
      loading: false,

      /**
       * @ngdoc property
       * @name error
       * @propertyOf sb.onboarding.OnboardingTemplatesModel
       *
       * @description
       * A string|null describing current error.
       */
      error: null,

      _load(prom) {
        this.loading = true;
        this.error = null;
        return prom
          .catch((err) => {
            this.error = angular.isString(err) ? err : 'Unable to complete action.';
            return $q.reject(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      /**
       * @ngdoc method
       * @name init
       * @methodOf sb.onboarding.OnboardingTemplatesModel
       *
       * @description
       * Inits the model.
       *
       * @returns {Promise} Resolves on completion or rejects on error.
       */
      init() {
        const prom = SimpleHTTPWrapper(
          {
            method: 'GET',
            url: FORMS_API_URL,
            params: {
              currentRevision: true,
              published: true,
            },
          },
          'Could not load templates.',
        ).then(({ templates }) => {
          this.templates = List(templates);
          return this.forms;
        });
        return this._load(prom);
      },

      /**
       * @ngdoc method
       * @name unpublish
       * @methodOf sb.onboarding.OnboardingTemplatesModel
       *
       * @description
       * unpublish template so it doesn't show up on on the table or in the process
       *
       * @param {object} template to delete.
       *
       * @returns {Promise} Resolves on completion or rejects on error.
       */
      unpublish(template) {
        const prom = SimpleHTTPWrapper(
          {
            method: 'DELETE',
            url: `${FORMS_API_URL}${template.baseName}`,
          },
          'Could not delete template.',
        ).then(() => {
          this.templates = this.templates.filter((tpl) => tpl.id !== template.id);
        });
        return this._load(prom);
      },

      /**
       * @ngdoc method
       * @name deleteRetired
       * @methodOf sb.onboarding.OnboardingTemplatesModel
       *
       * @description
       * Hard deletes all unpublished revisions of a template that are more recent than
       * the published version
       *
       * @param {string} templateBaseName ID of the template to delete.
       *
       * @returns {Promise} Resolves on completion or rejects on error.
       */
      deleteRetired(templateBaseName) {
        const endpoint = '/deleteretired';
        const prom = SimpleHTTPWrapper(
          {
            method: 'DELETE',
            url: `${FORMS_API_URL}${templateBaseName}${endpoint}`,
          },
          'Could not delete unsaved template changes.',
        ).then(() => {
          return this.templates;
        });
        return this._load(prom);
      },

      /**
       * @ngdoc method
       * @name disable
       * @methodOf sb.onboarding.OnboardingTemplatesModel
       *
       * @description
       * disables a template so it doesn't show up in the process and on
       * the onboarding forms page.
       *
       * @param {string} templateBaseName base name of the template to delete.
       *
       * @returns {Promise} Resolves on completion or rejects on error.
       */
      disable(templateBaseName) {
        const prom = SimpleHTTPWrapper(
          {
            method: 'PUT',
            url: FORMS_API_URL + templateBaseName + '/disable',
          },
          'Could not disable template',
        ).then(() => {
          return this.templates;
        });
        return this._load(prom);
      },

      /**
       * @ngdoc method
       * @name enable
       * @methodOf sb.onboarding.OnboardingTemplatesModel
       *
       * @description
       * enable a template so it shows up in the process and on
       * the onboarding forms page.
       *
       * @param {string} templateBaseName base name of the template to delete.
       *
       * @returns {Promise} Resolves on completion or rejects on error.
       */
      enable(templateBaseName) {
        const prom = SimpleHTTPWrapper(
          {
            method: 'PUT',
            url: FORMS_API_URL + templateBaseName + '/enable',
          },
          'Could not enable template',
        ).then(() => {
          return this.templates;
        });
        return this._load(prom);
      },

      /**
       * @ngdoc method
       * @name getLatestVersion
       * @methodOf sb.onboarding.OnboardingTemplatesModel
       *
       * @description
       * gets the latest revison of a template regardles of whether or not it's published
       *
       * @param {string} templateBaseName base name of the template for which we want the latest version.
       *
       * @returns {Promise} Resolves on completion or rejects on error.
       */
      getLatestVersion(templateBaseName) {
        const prom = SimpleHTTPWrapper(
          {
            method: 'GET',
            url: FORMS_API_URL,
            params: {
              latestRevision: true,
              publish: false,
            },
          },
          'Could not find latest template',
        ).then((resp) => {
          const { templates } = resp;
          return templates.filter(
            (template) => template.baseName === templateBaseName,
          )[0];
        });
        return this._load(prom);
      },
    });
  },
]; // end OnboardingTemplatesModel

/**
 * @ngdoc component
 * @name sb.onboarding.component:sbOnBoardingFormsTable
 *
 * @description
 * This is a component that displays onboarding forms with add, edit, and remove controls.
 */
export const sbOnboardingFormsTable = {
  template: require('./templates/onboarding-forms.html'),
  controllerAs: 'vm',
  controller: [
    '$scope',
    '$sbModal',
    '$window',
    '$observable',
    'PromiseErrorCatcher',
    'SimpleHTTPWrapper',
    'OnboardingTemplatesModel',
    function (
      $scope,
      $sbModal,
      $window,
      $observable,
      PromiseErrorCatcher,
      SimpleHTTPWrapper,
      OnboardingTemplatesModel,
    ) {
      function remove(template) {
        this.model.deleteRetired(template.id).catch(PromiseErrorCatcher);
      }

      function goToTemplateEditPage(baseName) {
        $window.location.replace(`./hr-create-form.html?id=${baseName}`);
      }

      function prevVersionModal(template) {
        this.model
          .getLatestVersion(template.baseName)
          .then((latest) => {
            if (template.id === latest.id) {
              goToTemplateEditPage(template.baseName);
            } else {
              $sbModal
                .open({
                  bindToController: true,
                  size: 'md',
                  controllerAs: 'vm',
                  template: require('./templates/prev-version-modal.html'),
                  resolve: {
                    baseName: () => template.baseName,
                    latestId: () => latest.id,
                    goToTemplateEditPage: () => goToTemplateEditPage,
                    templatesModel: () => this.model,
                  },
                  controller: prevVersionModalCtrl,
                })
                .result.catch(PromiseErrorCatcher);
            }
          })
          .catch(PromiseErrorCatcher);
      }

      function activeCheckboxModal(template) {
        this.model
          .getLatestVersion(template.baseName)
          .then((latest) => {
            if (template.id === latest.id) {
              if (template.active) {
                this.model.enable(template.baseName);
              } else {
                this.model.disable(template.baseName);
              }
            } else {
              $sbModal
                .open({
                  bindToController: true,
                  size: 'md',
                  controllerAs: 'vm',
                  template: require('./templates/active-checkbox-modal.html'),
                  resolve: {
                    baseName: () => template.baseName,
                    latestId: () => latest.id,
                    goToTemplateEditPage: () => goToTemplateEditPage,
                    templatesModel: () => this.model,
                    pce: () => PromiseErrorCatcher,
                  },
                  controller: activeCheckboxModalCtrl,
                })
                .result.then(
                  () => {
                    // noop on modal close
                    return true;
                  },
                  () => {
                    // reset checkbox if modal gets dismissed without any action being taken
                    template.active = !template.active;
                  },
                )
                .catch(PromiseErrorCatcher);
            }
          })
          .catch(PromiseErrorCatcher);
      }

      function $onInit() {
        const model = (this.model = OnboardingTemplatesModel());

        model
          .init()
          .then(() => {
            const changedTemplate$ = $observable
              .merge(
                ...this.model.templates.map((t, i) => {
                  return $observable.fromWatcher(
                    $scope,
                    `vm.model.templates.get(${i})`,
                    true,
                  );
                }),
              )
              .filter((val) => val.oldValue.active !== val.newValue.active)
              .map((val) => val.newValue);
            changedTemplate$.$applySubscribe($scope, (tpl) => {
              if (tpl.active) {
                this.model.enable(tpl.baseName).catch(PromiseErrorCatcher);
              } else {
                this.activeCheckboxModal(tpl);
              }
            });
          })
          .catch(PromiseErrorCatcher);
      }

      this.prevVersionModal = prevVersionModal.bind(this);
      this.activeCheckboxModal = activeCheckboxModal.bind(this);
      this.remove = remove.bind(this);
      this.$onInit = $onInit.bind(this);
    },
  ],
}; // end sbOnboardingFormsTable

const prevVersionModalCtrl = [
  'baseName',
  'latestId',
  'goToTemplateEditPage',
  'templatesModel',
  function (baseName, latestId, goToTemplateEditPage, templatesModel) {
    function deleteRetiredTemplateRevisions() {
      templatesModel.deleteRetired(baseName).then(() => this.goToEditPage());
    }

    function goToEditPage() {
      goToTemplateEditPage(baseName);
    }

    this.deleteRetiredTemplateRevisions = deleteRetiredTemplateRevisions.bind(this);
    this.goToEditPage = goToEditPage.bind(this);
  },
];

const activeCheckboxModalCtrl = [
  'baseName',
  'latestId',
  'goToTemplateEditPage',
  'templatesModel',
  'pce',
  function (baseName, latestId, goToTemplateEditPage, templatesModel, pce) {
    function deleteRetiredTemplateRevisions() {
      templatesModel
        .deleteRetired(baseName)
        .then(() => this.disable())
        .catch(pce);
    }

    function goToEditPage() {
      goToTemplateEditPage(baseName);
    }

    function disableTemplate() {
      templatesModel
        .disable(baseName)
        .then(() =>
          templatesModel
            .init()
            .then(() => this.$close())
            .catch(pce),
        )
        .catch(pce);
    }

    this.deleteRetiredTemplateRevisions = deleteRetiredTemplateRevisions.bind(this);
    this.goToEditPage = goToEditPage.bind(this);
    this.disable = disableTemplate.bind(this);
  },
];
