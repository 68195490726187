<input
  #inputField
  type="text"
  [attr.id]="id"
  [attr.name]="id"
  [min]="min"
  [max]="max"
  [ngModel]="formattedValue"
  (ngModelChange)="formatValue($event)"
  (focus)="handleFocus($event)"
  (blur)="handleBlur($event)"
  [ngClass]="{ 'has-errors': showError, 'sbx-number-textline-base': true }"
  [disabled]="to.readOnly ? '' : null"
/>
<div *ngIf="errorsMin" class="sbx-form-field-error">
  Value must be at least {{ min }}.
</div>
<div *ngIf="errorsMax" class="sbx-form-field-error">
  Value must be at most {{ max }}.
</div>
