import angular from 'angular';

/**
 * @ngdoc object
 * @kind function
 * @name sb.process.object:SerializeAndSubmitProcessForm
 *
 * @description
 * This is the standard way to submit a process.
 *
 * @param {string} buttonName The button the process is being submited with.
 * @param {object} [extraData=undefined] Addtional data to submit to the process
 *    in the form of `{ <name>: <value> }`.
 * @param {boolean} [removeButtonPrefix=false] If true, do not add the prefix to
 *    button form data. This param is *deprecated* and is only sticking around
 *    to support old zope form list widgets.
 *
 * @returns {promise} Resolves when the process was successfully submited.
 */
export const SerializeAndSubmitProcessForm = [
  '$rootScope',
  '$location',
  '$q',
  '$window',
  '$httpParamSerializerJQLike',
  'ProcessModel',
  'ProcessButtonModel',
  'ProcessUrlInfo',
  'ProcessStatus',
  function (
    $rootScope,
    $location,
    $q,
    $window,
    $httpParamSerializerJQLike,
    ProcessModel,
    ProcessButtonModel,
    ProcessUrlInfo,
    ProcessStatus,
  ) {
    function GetDataWithButton(buttonName, extraData, removeButtonPrefix) {
      const extra = $httpParamSerializerJQLike(extraData);
      let formData = angular.element('.process > form').serialize();
      const pmPrefix = ProcessModel.$wi.formPrefix;
      let prefix = angular.isUndefined(pmPrefix) ? 'form.' : pmPrefix;
      if (prefix.length && prefix[prefix.length - 1] !== '.') {
        prefix += '.';
      }
      if (formData.length) {
        formData += '&';
      }
      if (!removeButtonPrefix) {
        formData += prefix + 'buttons.';
      }
      formData += buttonName + '=true';
      if (extra.length) {
        formData += '&' + extra;
      }
      return formData;
    }

    const IS_PARENT_PROCESS = ProcessUrlInfo.isParentProcess();

    return function (buttonName, extraData, removeButtonPrefix) {
      if (buttonName === 'discard') {
        $window.location.href = ProcessUrlInfo.abortUrl();
        return $q.reject();
      } else if (buttonName === 'back') {
        const $wi = ProcessModel.$wi;
        if ($wi.wizardPosition > 0) {
          const gotoStep = $wi.wizard[$wi.wizardPosition - 1];

          ProcessModel.$resolveWorkItemId(gotoStep.linkId).then((workItemId) =>
            $location.path(`/${workItemId}`),
          );
        }
        return $q.reject();
      }
      ProcessStatus.$setStatus();
      return ProcessButtonModel.$requestSubmit(buttonName)
        .then(() => {
          return $q((resolve) => {
            // Wrapping in applyAsync so that DOM is renewed before serialization.
            $rootScope.$applyAsync(() => {
              resolve(
                GetDataWithButton(buttonName, extraData || {}, removeButtonPrefix),
              );
            });
          });
        })
        .then((formData) => {
          return ProcessModel.$submit(formData);
        })
        .then(
          (newId) => {
            if (!newId && IS_PARENT_PROCESS) {
              // Undefined means process over.
              $window.location.href = ProcessUrlInfo.doneUrl();
              return;
            } else if (newId) {
              $location.path(`/${newId}`);
            } else {
              $window.parent.postMessage(
                {
                  action: 'processComplete',
                },
                '*',
              );
            }
            if (!IS_PARENT_PROCESS) {
              // Here we use $location.replace as not to affect history.
              $location.replace();
            }
          },
          (reason) => {
            if (reason) {
              if (ProcessModel.$lastLoadResult === 419) {
                ProcessStatus.$setStatus(reason, 'info');
              } else {
                ProcessStatus.$setStatus(reason, 'danger');
              }
            }
            return $q.reject(reason);
          },
        );
    };
  },
]; // end SerializeAndSubmitProcessForm

/**
 * @ngdoc directive
 * @name sb.process.directive:sbProcess
 * @restrict EA
 *
 * @description
 * A reusable directive to display the process "viewportal."
 *
 * @element ANY
 * @param {string} sbProcessIsBare If `sbProcessIsBare === 'true'`, then the
 *    process viewport will render itself with the "bare" style (ie, no
 *    workitem title, etc).
 */
export function sbProcess() {
  return {
    restrict: 'EA',
    template: require('./templates/process.html'),
    controller: 'ProcessCtrl',
  };
}

/**
 * @ngdoc directive
 * @name sb.process.directive:sbWiHtml
 * @restrict A
 *
 * @description
 * This directive takes workitem HTML and $compiles it into the process view.
 *
 * @element ANY
 * @param {string} sbWiHtml HTML string that will be the workitem.
 */
export const sbWiHtml = [
  '$rootScope',
  '$animate',
  '$compile',
  'ProcessButtonModel',
  function ($rootScope, $animate, $compile, ProcessButtonModel) {
    return {
      restrict: 'A',
      scope: {
        content: '&sbWiHtml',
      },
      link: function (scope, element) {
        let childScope;

        function loadWi() {
          element.html(scope.content());
          if (childScope) {
            childScope.$destroy();
          }

          // Clean up all process button handlers, because we will recreate
          // them on $compile.
          // HACK: We don't re-add the submit conditions added in the
          // buttons() watcher in sbProcessButtons if we loading a new wi
          // via the continue button. Don't clear the submit
          // if there's a child scope
          ProcessButtonModel.$clear(childScope);

          childScope = scope.$new(false);
          $compile(element.contents())(childScope);
          $rootScope.$broadcast('sbWiHtml::contentLoaded');
          $animate.addClass(element, 'reveal-active');
        }

        scope.$watch('content()', (nv) => {
          if (!nv) {
            return;
          }
          if (element.hasClass('reveal-active')) {
            $animate.removeClass(element, 'reveal-active').then(loadWi);
          } else {
            loadWi();
          }
        });
      },
    };
  },
]; // end sbWiHtml
