import domtoimage from 'dom-to-image';
const FileSaver = require('file-saver');

const sbOrgCharts = {
  controllerAs: 'vm',
  bindings: {
    title: '@',
    goBackUrl: '@',
    organizationalData: '<',
    isHighlighted: '<',
    canStartJobChangeEvent: '<',
    jobChangeLinkTemplate: '<',
    updateOrgChartLinkTemplate: '<',
    chartTypes: '<',
    maxAdjNodes: '<',
  },
  template: require('./templates/orgcharts.html'),
  controller: [
    '$scope',
    '$document',
    '$timeout',
    'SimpleHTTPWrapper',
    'BackendLocation',
    class {
      constructor($scope, $document, $timeout, SimpleHTTPWrapper, BackendLocation) {
        this.$scope = $scope;
        this.$document = $document;
        this.$timeout = $timeout;
        this.SimpleHTTPWrapper = SimpleHTTPWrapper;
        this.BackendLocation = BackendLocation;

        this.organizationalData = null;
        this.canStartJobChangeEvent = false;
        this.jobChangeLinkTemplate = null;
        this.updateOrgChartLinkTemplate = null;
        this.isHighlighted = false;
        this.chartTypes = null;
        this.chartTypeSelected = null;
        this.maxAdjNodes = 5;
      }

      $postLink() {
        if (this.chartTypes) {
          this.chartTypeSelected = this.chartTypes[0];
          if (this.organizationalData.errors) {
            this.errors = this.organizationalData.errors;
          }
        }
      }

      getOrgchartData() {
        this.SimpleHTTPWrapper({
          method: 'GET',
          url:
            this.BackendLocation.entity(1) + `orgchart?type=${this.chartTypeSelected}`,
        }).then((data) => {
          this.organizationalData = data;
          this.$timeout(() => this.$scope.$broadcast('orgchart:showAll'), 0);
        });
      }

      highlightDepartments() {
        this.isHighlighted = !this.isHighlighted;
        this.$scope.$broadcast('orgchart:highlightDepartments');
      }

      showAll() {
        this.$scope.$broadcast('orgchart:showAll');
      }

      exportChart() {
        const node = this.$document.find('.orgchart')[0];
        const toggleButtonsEl = Array.from(this.$document.find('.toggleBtn'));
        const showAllButtonsEl = Array.from(this.$document.find('.show-all.bottom'));

        node.style.transform = 'matrix(1, 0, 0, 1, 0, 0)';

        toggleButtonsEl.forEach((n) => {
          n.style.display = 'none';
        });

        showAllButtonsEl.forEach((n) => {
          n.style.display = 'none';
        });

        domtoimage.toBlob(node, { cacheBust: true }).then((blob) => {
          FileSaver.saveAs(blob, 'orgchart.png');
          toggleButtonsEl.forEach((n) => {
            n.style.display = 'inline-block';
          });

          showAllButtonsEl.forEach((n) => {
            n.style.display = 'block';
          });
        });
      }
    },
  ],
};

export default sbOrgCharts;
