import angular from 'angular';
const INIT_ERROR = 'Could not fetch emails.';
const UNIQUE_EMAIL_ERROR = 'This email already exists.';
const EMAIL_INFO =
  'Changes, including new email addresses, will not be updated ' +
  'until you save your changes (at the bottom of the page).';
const INVALID_WORK_EMAIL = 'Please enter a valid work email.';
const SAVE_ERROR = 'There was an error saving email preferences.';
const INVALID_EMAIL_ERROR = 'Please enter a valid email address.';
const REMOVE_ERROR = 'Could not remove email.';
const EMAIL_SAVED = 'Email preferences have been saved.';
function emailSaveSucces(newEmails) {
  if (!newEmails.length) {
    return EMAIL_SAVED;
  }
  const emailList = newEmails.join(', ');
  if (newEmails.length === 1) {
    return EMAIL_SAVED + '<br/>' + sentVerificationEmailMessage(emailList);
  }
  return (
    EMAIL_SAVED +
    '<br/>Emails have been sent to: <strong>' +
    emailList +
    '</strong>. ' +
    'Please follow the links in each message to verify the account.'
  );
}
function sentVerificationEmailMessage(email) {
  return (
    'An email has been sent to <strong>' +
    email +
    '</strong>. ' +
    'Please follow the link in that message to verify the account.'
  );
}
/**
 * @ngdoc object
 * @name sb.stakeholderProfile:StakeholderProfileEmailModel
 *
 * @description
 * The state container for stakeholder email page.
 */
export const StakeholderProfileEmailModel = [
  '$q',
  'BackendLocation',
  'SimpleHTTPWrapper',
  function ($q, BackendLocation, SimpleHTTPWrapper) {
    class Model {
      constructor(endpoint) {
        this._endpoint = endpoint;

        /**
         * @ngdoc property
         * @name emails
         * @propertyOf sb.stakeholderProfile.StakeholderProfileEmailModel
         *
         * @description
         * List of currently {object} emails.
         *   @property {string} value The email address.
         *   @property {boolean} isVerified If the email has been verified.
         *   @property {boolean} isBlacklisted If the email has been blacklisted.
         *   @property {boolean} verifyingOutstanding If the verification request
         *     is outstanding.
         *   @property {boolean} isNew Boolean if the email is not yet persisted.
         */
        this.emails = [];

        /**
         * @ngdoc property
         * @name recoveryEmails
         * @propertyOf sb.stakeholderProfile.StakeholderProfileEmailModel
         *
         * @description
         * List of currently {string} recoveryEmails.
         */
        this.recoveryEmails = [];

        /**
         * @ngdoc property
         * @name preferredEmail
         * @propertyOf sb.stakeholderProfile.StakeholderProfileEmailModel
         *
         * @description
         * {string} Current preferred email.
         */
        this.preferredEmail = '';

        /**
         * @ngdoc property
         * @name workEmail
         * @propertyOf sb.stakeholderProfile.StakeholderProfileEmailModel
         *
         * @description
         * {string} One of the users work emails.
         */
        this.workEmail = '';

        /**
         * @ngdoc property
         * @name inviteEmail
         * @propertyOf sb.stakeholderProfile.StakeholderProfileEmailModel
         *
         * @description
         * {string} Invite email for stakeholder.
         */
        this.inviteEmail = '';

        /**
         * @ngdoc property
         * @name newEmail
         * @propertyOf sb.stakeholderProfile.StakeholderProfileEmailModel
         *
         * @description
         * {string} Current user typed new email.
         */
        this.newEmail = '';

        /**
         * @ngdoc property
         * @name loading
         * @propertyOf sb.stakeholderProfile.StakeholderProfileEmailModel
         *
         * @description
         * {boolean} Is the model currently oustanding?
         */
        this.loading = false;

        /**
         * @ngdoc property
         * @name initialized
         * @propertyOf sb.stakeholderProfile.StakeholderProfileEmailModel
         *
         * @description
         * {boolean} Has the model initialized yet?
         */
        this.initialized = false;

        /**
         * @ngdoc property
         * @name isInitializing
         * @propertyOf sb.stakeholderProfile.StakeholderProfileEmailModel
         *
         * @description
         * {boolean} Is current user still in the process of being created
         */
        this.isInitializing = false;

        /**
         * @ngdoc property
         * @name hasUser
         * @propertyOf sb.stakeholderProfile.StakeholderProfileEmailModel
         *
         * @description
         * {boolean} Does the stakeholder have a user yet?
         */
        this.hasUser = false;
      }

      _recoveryEmails(emails) {
        return emails.filter((email) => email.isVerified).map((email) => email.value);
      }

      _load(
        { emails, preferredEmail, recoveryEmail, workEmail, inviteEmail },
        loadWork,
      ) {
        this.emails = emails;
        this.recoveryEmail = recoveryEmail;
        this.recoveryEmails = this._recoveryEmails(emails);
        this.preferredEmail = preferredEmail;
        this.email = inviteEmail;
        if (loadWork) {
          this.workEmail = workEmail;
        }
        for (const emailInfo of emails) {
          if (emailInfo.value === inviteEmail && emailInfo.isVerified === false) {
            this.isInitializing = true;
          }
        }
      }

      _saveEmails() {
        return SimpleHTTPWrapper(
          {
            url: this._endpoint,
            method: 'POST',
            data: {
              emails: this.emails.map((email) => email.value),
              recoveryEmail: this.recoveryEmail,
              preferredEmail: this.preferredEmail,
              inviteEmail: this.inviteEmail,
            },
          },
          'Could not save emails.',
        ).then((data) => {
          this._load(data, false);
          return data.newEmails || [];
        });
      }

      _saveWorkEmail() {
        return SimpleHTTPWrapper(
          {
            url: this._endpoint + 'Work',
            method: 'POST',
            data: {
              workEmail: this.workEmail,
            },
          },
          'Could not save emails.',
        ).then((data) => {
          this._load(data, true);
          return data.newEmails || [];
        });
      }

      _saveInitEmail() {
        if (!this.email) {
          return Promise.reject('Invite Email is required.');
        }
        const endpoint = BackendLocation.context('2').replace(
          'stakeholders',
          'stakeholder',
        );
        const editStakeholder = endpoint + 'edit-stakeholder';
        return SimpleHTTPWrapper(
          {
            url: editStakeholder,
            method: 'GET',
          },
          'Could not get user data',
        ).then((data) => {
          const model = data.model;
          // eslint-disable-next-line camelcase
          model.work_email = this.workEmail;
          return SimpleHTTPWrapper(
            {
              url: editStakeholder,
              method: 'POST',
              data: model,
            },
            'Could not update user email',
          );
        });
      }

      /**
       * @ngdoc method
       * @name init
       * @methodOf sb.stakeholderProfile.StakeholderProfileEmailModel
       *
       * @description
       * Hydrate the model.
       *
       * @returns {promise} Returns a promise that resolves/rejects on async
       *   success/failure.
       */
      init() {
        this.initialized = false;
        this.loading = true;
        return SimpleHTTPWrapper({ url: this._endpoint }, 'Could not fetch emails.')
          .then((data) => {
            this._load(data, true);
          })
          .finally(() => {
            this.initialized = true;
            this.loading = false;
          });
      }

      /**
       * @ngdoc method
       * @name save
       * @methodOf sb.stakeholderProfile.StakeholderProfileEmailModel
       *
       * @description
       * Persist the current state (sends preferredEmail, recoveryEmail, and
       * all emails).
       *
       * @returns {promise} Returns a promise that resolves/rejects on async
       *   success/failure. The resolution will be an array of any newly added email
       *   values.
       */
      save(canEditUserInfo, canEditStakeholderInfo) {
        this.loading = true;
        let prom;
        if (!this.hasUser || this.isInitializing) {
          prom = this._saveInitEmail();
        } else if (canEditUserInfo && canEditStakeholderInfo) {
          // If user can do both, lets do them in serial so that the backend
          // doesn't get conflicts. XXX This is not a good idea. We should not be doing
          // multiple requests where one suffices, but until we have something like
          // graphql to collapse requests into one, this is what we're stuck with.
          prom = this._saveEmails().then((start) =>
            this._saveWorkEmail().then((other) => start.concat(other)),
          );
        } else if (canEditUserInfo) {
          prom = this._saveEmails();
        } else {
          prom = this._saveWorkEmail();
        }
        return prom.finally(() => {
          this.loading = false;
        });
      }

      /**
       * @ngdoc method
       * @name deleteEmail
       * @methodOf sb.stakeholderProfile.StakeholderProfileEmailModel
       *
       * @param {string} email String value of the deletion email.
       *
       * @returns {promise} Returns a promise that resolves/rejects on async
       *   success/failure.
       */
      deleteEmail(email) {
        const removeEmail = () => {
          this.emails = this.emails.filter((e) => e.value !== email);
          this.recoveryEmails = this._recoveryEmails(this.emails);
        };
        const find = this.emails.find((e) => e.isNew && e.value === email);
        if (find) {
          removeEmail();
          return $q.resolve();
        }
        return SimpleHTTPWrapper(
          {
            method: 'DELETE',
            url: this._endpoint,
            data: { email },
          },
          'Could not delete email.',
        ).then(removeEmail);
      }

      /**
       * @ngdoc method
       * @name addNewEmail
       * @methodOf sb.stakeholderProfile.StakeholderProfileEmailModel
       *
       * @description
       * Adds `newEmail` to `unsavedEmails`. This does not take a persitent action.
       *
       * @returns {string|null} Returns error string or `null`;
       */
      addNewEmail() {
        const { newEmail, emails } = this;
        const find = emails.find((email) => email.value === newEmail);
        if (find) {
          return UNIQUE_EMAIL_ERROR;
        }
        emails.push({ value: newEmail, isNew: true });
        this.newEmail = '';
        return null;
      }
    }
    return () => new Model(BackendLocation.context('1') + 'emails');
  },
]; // end StakeholderProfileEmailModel

/**
 * @ngdoc component
 * @name sb.stakeholderProfile.component:sbStakeholderEmailPage
 *
 * @description
 * This component renders the email editing part of stakeholder profile.
 */
export const sbStakeholderEmailPage = {
  controllerAs: 'vm',
  template: require('./templates/email-page.html'),
  bindings: {
    stakeholder: '<',
    canEditUserInfo: '<',
    canInvite: '<',
    canEditStakeholderInfo: '<',
    canEditWorkEmail: '<',
  },
  controller: [
    'StakeholderProfileEmailModel',
    'AppConfig',
    function (StakeholderProfileEmailModel, AppConfig) {
      function statusCallBack(status) {
        if (status.changes && status.model.sh_type === 'individual') {
          this.model.init();
        }
        if (status.success) {
          this.bannerType = 'success';
        } else {
          this.bannerType = 'danger';
        }
        this.bannerMessage = status.message;
        if (status.model.sh_type === 'entity') {
          this.bannerMessage += `
        <a href="${AppConfig.currentEntity.url}/relationships/${this.stakeholder.id}">
          Click here
        </a> to manage the relationship.`;
        }
      }

      function save() {
        const {
          canEditUserInfo,
          canEditStakeholderInfo,
          canEditWorkEmail,
          emailForm,
          model,
        } = this;
        const isInvalidWorkEmail =
          canEditWorkEmail && canEditStakeholderInfo && emailForm.workEmail.$invalid;
        const isInvalid = isInvalidWorkEmail;

        if (isInvalid) {
          this.bannerType = 'danger';
          this.bannerMessage = INVALID_WORK_EMAIL;
          return;
        }

        this.bannerMessage = null;
        model.save(canEditUserInfo, canEditStakeholderInfo).then(
          (newEmails) => {
            this.bannerType = 'success';
            this.bannerMessage = emailSaveSucces(newEmails);
          },
          (error) => {
            this.bannerType = 'danger';
            this.bannerMessage = angular.isString(error) ? error : SAVE_ERROR;
          },
        );
      }
      function addEmail() {
        const { $invalid } = this.emailForm.newEmail;
        if ($invalid) {
          this.bannerType = 'danger';
          this.bannerMessage = INVALID_EMAIL_ERROR;
          return;
        }
        const emailError = this.model.addNewEmail();
        if (emailError) {
          this.bannerType = 'danger';
          this.bannerMessage = emailError;
          return;
        }
        if (this.bannerType === 'danger') {
          this.bannerMessage = null;
        }
      }
      function onVerify(email, error) {
        if (error) {
          this.bannerType = 'danger';
          this.bannerMessage = error;
        } else {
          this.bannerType = 'success';
          this.bannerMessage = sentVerificationEmailMessage(email);
        }
      }
      function remove(email) {
        this.model.deleteEmail(email).catch(() => {
          this.bannerType = 'danger';
          this.bannerMessage = REMOVE_ERROR;
        });
      }
      this.$onInit = () => {
        if (this.canEditUserInfo) {
          this.bannerType = 'info';
          this.bannerMessage = EMAIL_INFO;
        }
        this.model = StakeholderProfileEmailModel();
        this.model.hasUser = !this.stakeholder.virtual;
        this.save = save.bind(this);
        this.onVerify = onVerify.bind(this);
        this.remove = remove.bind(this);
        this.addEmail = addEmail.bind(this);
        this.statusCallBack = statusCallBack.bind(this);

        this.model.init().catch(() => {
          this.bannerType = 'danger';
          this.bannerMessage = INIT_ERROR;
        });
      };
    },
  ],
}; // end sbStakeholderEmailPage

/**
 * @ngdoc component
 * @name sb.stakeholderProfile.component:sbStakeholderEmailSelector
 *
 * @description
 * This component renders the list of persisted emails and preferred selection.
 *
 * @param {expression} ngModel Model assignment expression. This will be the
 *    currently selected preferred email.
 * @param {Array} emails Array of email objects to show:
 *    @property {string} value The email value
 *    @property {boolean} isVerified Boolean if the email is verified.
 *    @property {boolean} isBlacklisted Boolean if the email is blacklisted.
 *    @property {boolean} verifyingOutstanding Boolean if the email being verified.
 *    @property {boolean} isNew Boolean if the email is not yet persisted.
 * @param {string} workEmail The current value of the work email to hide delete buttons.
 * @param {object} stakeholder The stakeholder who owns the emails.
 * @param {boolean} [disabled=false] Boolean if delete/verify are disabled.
 * @param {expression} deleteEmail This expression will be evaluated when
 *    user clicks delete button. `$item` will be in expression namespace.
 * @param {expression} [onVerify=undefined] This expression will be evaluated when
 *    outstanding verify request comes back. `$item` and `$error` (on failure) will be
 *    in namespace.
 */
export const sbStakeholderEmailSelector = {
  controllerAs: 'vm',
  template: require('./templates/email-selector.html'),
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
    emails: '<',
    workEmail: '<',
    stakeholder: '<',
    disabled: '<?',
    deleteEmail: '&',
    onVerify: '&?',
  },
  controller: [
    function () {
      function changePreferred(item) {
        this.ngModelCtrl.$setViewValue(item.value, 'click');
      }
      this.changePreferred = changePreferred.bind(this);
    },
  ],
}; // end sbStakeholderEmailSelector
