import angular from 'angular';
import ngAnimate from 'angular-animate';
import ngSanitize from 'angular-sanitize';
import Modal from '../modal/modal';

import { Auth, sbLoginForm } from './authentication';
import Form from './../form/form';
import Messages from './../messages/messages';
import sbBootstrap from '../bootstrap/bootstrap';

/**
 *
 * @ngdoc overview
 * @name sb.lib.auth
 *
 * @description
 * This module houses authentication code.
 */
export default angular
  .module('sb.lib.auth', [ngSanitize, Modal, Form, sbBootstrap, ngAnimate, Messages])

  .factory('Auth', Auth)
  .directive('sbLoginForm', sbLoginForm).name;
