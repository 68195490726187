/**
 * @ngdoc directive
 * @name sb.billing.order.sbOrderHistory
 *
 * This is a directive that creates a Billing Tickets Page
 *
 * @description
 * A button that brings up a modal that allows to complete a task.
 * Parameters to initialize the pagelist model.
 **/
export const sbOrderHistory = [
  'ListPageModel',
  'ListLocation',
  'BackendLocation',
  function (ListPageModel, ListLocation, BackendLocation) {
    return {
      restrict: 'E',
      template: require('./templates/order-history.html'),
      scope: {
        title: '@sbTitle',
        totalSize: '<sbTotalSize',
        sortForms: '<sbSorts',
        actions: '<sbActions',
        sortData: '<sbSortData',
        size: '<sbSize',
        sort: '<sbSort',
        paging: '<sbPaging',
        items: '<sbItems',
        renewals: '<sbRenewals',
      },
      controller: [
        '$scope',
        function ($scope) {
          $scope.model = ListPageModel;
          $scope.redirect = '/';
          $scope.isNaN = isNaN;
          $scope.total = (order) => {
            const total =
              order.items.reduce(
                (item1, item2) => {
                  return { total: item1.total + item2.total };
                },
                { total: 0 },
              ).total / 100;

            const refunds = order.refunds || [];
            const refundTotal =
              refunds.reduce((total, refund) => {
                // eslint-disable-next-line camelcase
                return total + refund.amount;
              }, 0) / 100;

            if (refundTotal === total) {
              return 'Refunded';
            } else if (refundTotal > 0) {
              return `Partially refunded ($${refundTotal})`;
            }

            return total;
          };
          $scope.model.initialize(
            undefined,
            undefined,
            $scope.size,
            $scope.sortData,
            $scope.paging,
            $scope.items,
            undefined,
            { url: BackendLocation.entity(1) + '/orders' },
            ListLocation.updateUrl,
          );
          ListLocation.updateModelOnLocationChange($scope);

          $scope.setStatus = (msg) => {
            $scope.status = msg;
          };
        },
      ],
    };
  },
];

/**
 * @ngdoc directive
 * @name sb.billing.orders.sbChargeCustomer
 *
 * @description
 * This is a directive that creates a customer order
 *
 **/
export const sbCreateOrder = [
  'BillingService',
  function (BillingService) {
    return {
      restrict: 'E',
      template: require('./templates/charge-customer.html'),
      scope: {
        form: '<sbFormDescription',
        onSuccess: '<sbSuccessCallBack',
        orderData: '<sbOrderData',
      },
      controller: [
        '$scope',
        '$sbModal',
        'PromiseErrorCatcher',
        function ($scope, $sbModal, PromiseErrorCatcher) {
          $scope.formModel = $scope.orderData || {};
          $scope.isUpdate = $scope.formModel.id;

          $scope.$watch('formModel.product_name', (nv) => {
            if (!nv) {
              return;
            }
            BillingService.findProduct(nv).then((data) => {
              $scope.formModel.price = data.price;
              $scope.formModel.description = data.description;
            });
          });

          $scope.openModal = () => {
            $sbModal
              .open({
                bindToController: true,
                size: 'lg',
                controllerAs: 'vm',
                template: require('./templates/customer-order-modal.html'),
                controller: 'CustomerOrderCtrl',
                resolve: {
                  callBack: () => $scope.onSuccess,
                  formModel: () => $scope.formModel,
                  OrderForm: () => {
                    return $scope.form;
                  },
                },
              })
              .result.catch(PromiseErrorCatcher);
          };
        },
      ],
    };
  },
];

export const CustomerOrderCtrl = [
  '$scope',
  'formModel',
  'OrderForm',
  'callBack',
  'BillingService',
  function ($scope, formModel, OrderForm, callBack, BillingService) {
    this.form = OrderForm;
    this.errors = {};
    this.callBack = callBack;
    this.formModel = formModel;
    this.loading = false;
    this.formUpdateUrl = './billing/renewal_info';

    function charge() {
      this.loading = true;
      return BillingService.charge(this.formModel)
        .then(
          (ticket) => {
            this.$dismiss();
            this.callBack(ticket);
          },
          (errors) => {
            this.errors = errors;
          },
        )
        .finally(() => {
          this.loading = false;
        });
    }

    function updateOrder() {
      this.loading = true;
      return BillingService.updateOrder(this.formModel)
        .then(
          () => {
            this.$dismiss();
            this.callBack();
          },
          (errors) => {
            this.errors = errors;
          },
        )
        .finally(() => {
          this.loading = false;
        });
    }

    function errorMessage() {
      const keys = Object.keys(this.errors);
      return keys && this.errors[keys[0]];
    }

    this.charge = charge.bind(this);
    this.updateOrder = updateOrder.bind(this);
    this.errorMessage = errorMessage.bind(this);
  },
]; // end CustomerOrderCtrl

export const BillingService = [
  'BackendLocation',
  'SimpleHTTPWrapper',
  function (BackendLocation, SimpleHTTPWrapper) {
    const ENTITY_TICKETS_URL = BackendLocation.entity('1') + 'tickets';
    const ENTITY_BILLING_TICKETS_URL = BackendLocation.entity('1') + 'billing';
    const COMPLETE_ERROR = 'There was an error creating invoice.';
    const TICKET_DETAIL_ERROR = 'Failed to find ticket.';

    return {
      charge(data) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${ENTITY_BILLING_TICKETS_URL}/charge`,
            data: data,
          },
          { chargeError: COMPLETE_ERROR },
        );
      },
      updateOrder(data) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${ENTITY_BILLING_TICKETS_URL}/update_order`,
            data: data,
          },
          { updateError: COMPLETE_ERROR },
        );
      },
      details(ticketId) {
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: `${ENTITY_TICKETS_URL}/${ticketId}`,
          },
          { ticketError: TICKET_DETAIL_ERROR },
        );
      },
      findProduct(productName) {
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: `${ENTITY_BILLING_TICKETS_URL}/product_info`,
            params: { name: productName },
          },
          { productError: COMPLETE_ERROR },
        );
      },
    };
  },
];
// end ProductService
