import angular from 'angular';
/**
 * @ngdoc directive
 * @name sb.process.directive:sbWizard
 * @restrict A
 *
 * @description
 * Manages the display of the process's wizard.
 */
export const sbWizard = [
  '$window',
  function ($window) {
    const STEP_WIDTH = 200;
    return {
      restrict: 'A',
      template: require('./templates/wizard.html'),
      scope: {
        steps: '=sbWizard',
        hasGroups: '&sbWizardHasGroups',
        currentStep: '=sbWizardCurrentStep',
        currentId: '=sbWizardCurrentId',
        load: '=sbWizardFirstLoad',
        goto: '&?sbWizardGoto',
      },
      controller: [
        '$scope',
        function ($scope) {
          /**
           * Compute wizard distance from right edge of view. Positive numbers
           * indicate the ribbon is scrolled off to the right (a good thing).
           */
          function ribbonFromRight() {
            if (!$scope.steps) {
              return false;
            }
            return (
              $scope.steps.length * STEP_WIDTH - $scope.width + $scope.wizardScroll
            );
          }

          /**
           * Wizard Scroll Left/Right State
           * Since state is so tiny and this is the only controller using it,
           * there's no need for a service.
           */

          $scope.wizardScroll = $scope.offset;
          $scope.wizardScrollLeft = function () {
            let scrollTo = $scope.wizardScroll + 2 * STEP_WIDTH;
            if (scrollTo > $scope.offset) {
              scrollTo = $scope.offset;
            }
            $scope.wizardScroll = scrollTo;
          };
          $scope.canScrollRight = function () {
            const right = ribbonFromRight();
            return right !== false && right > 0;
          };
          $scope.wizardScrollRight = function () {
            let scrollTo = $scope.wizardScroll - 2 * STEP_WIDTH;
            const right = ribbonFromRight();
            const diff = right - 2 * STEP_WIDTH;
            if (diff < 0) {
              scrollTo -= diff;
            }
            $scope.wizardScroll = scrollTo;
          };
          $scope.$watchGroup(['steps', 'width', 'hasGroups()'], () => {
            $scope.offset = $scope.hasGroups() ? 300 : 0;
            // Scroll until new workitem is the second item,
            // paying attention to bounds.
            if (!$scope.steps) {
              return;
            }
            let scrollTo = ($scope.currentStep - 1) * STEP_WIDTH * -1 + $scope.offset;
            const right = ribbonFromRight();
            const diff = right - ($scope.wizardScroll - scrollTo);
            if (diff < 0) {
              scrollTo -= diff;
            }
            if (scrollTo >= $scope.offset) {
              scrollTo = $scope.offset;
            }

            $scope.wizardScroll = scrollTo;
          });

          $scope.isCurrent = (step) => step.linkId === $scope.currentId;
        },
      ],
      link: function (scope, element) {
        scope.$watch(
          () => {
            return element.width();
          },
          (nv) => {
            scope.width = nv;
          },
        );
        angular.element($window).on('resize.wizard', () => {
          scope.$applyAsync();
        });
        scope.$on('$destroy', () => {
          angular.element($window).off('.wizard');
        });
      },
    };
  },
]; // end sbWizard

/**
 * @ngdoc directive
 * @name sb.process.directive:sbWizardGroup
 * @restrict A
 *
 * @description
 * Displays the wizard group information.
 */
export const sbWizardGroup = [
  '$compile',
  function ($compile) {
    const POPOVER_TEMPLATE = require('./templates/wizard-group-popover.html');
    return {
      restrict: 'A',
      scope: {
        groups: '=sbWizardGroup',
        activeGroup: '=sbWizardGroupActive',
      },
      template: require('./templates/wizard-group.html'),
      link: function (scope) {
        scope.$watch('groups', () => {
          scope.popoverContent = $compile(POPOVER_TEMPLATE)(scope);
        });
      },
    };
  },
]; // end sbWizardGroup
