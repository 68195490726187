import { ModuleWithProviders, NgModule } from '@angular/core';
import { SbxPropertiesListTemplatedComponent } from './sbx-properties-list-templated.component';
import { SbxPropertiesListModule } from '../sbx-properties-list/sbx-properties-list.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, SbxPropertiesListModule],
  declarations: [SbxPropertiesListTemplatedComponent],
  exports: [SbxPropertiesListTemplatedComponent],
})
export class SbxPropertiesListTemplatedModule {
  static entryComponents = [SbxPropertiesListTemplatedComponent];

  static forRoot(): ModuleWithProviders<SbxPropertiesListTemplatedModule> {
    return {
      ngModule: SbxPropertiesListTemplatedModule,
    };
  }
}
