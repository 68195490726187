import angular from 'angular';
import sbBootstrap from '../lib/bootstrap/bootstrap';
import Tools from '../lib/tools';
import Form from '../lib/form/form';
import Table from '../lib/table';
import RX from '../lib/rx';
import Tickets from '../lib/tickets';

import {
  sbOrderHistory,
  sbCreateOrder,
  CustomerOrderCtrl,
  BillingService,
} from './orders';
import { BillingAdminModel, sbBillingAdministration } from './admin';

/**
 * @ngdoc overview
 * @name sb.billing
 *
 * @description
 * This module houses billing
 */
export default angular
  .module('sb.billing', [sbBootstrap, Tools, Form, Table, Tickets, RX])

  .component('sbBillingAdministration', sbBillingAdministration)
  .directive('sbOrderHistory', sbOrderHistory)
  .directive('sbCreateOrder', sbCreateOrder)
  .controller('CustomerOrderCtrl', CustomerOrderCtrl)
  .factory('BillingAdminModel', BillingAdminModel)
  .factory('BillingService', BillingService).name;
