import angular from 'angular';
/**
 * @ngdoc controller
 * @name sb.lib.multistep.controller:PermissionsWizardCtrl
 *
 * @description
 * This controller is the parent controller to be used in ashow page that contains a
 * multistep component
 *
 */

export const ShowPageWizardCtrl = [
  '$scope',
  'SerializeAndSubmitProcessForm',
  'PromiseErrorCatcher',
  function ($scope, SerializeAndSubmitProcessForm, PromiseErrorCatcher) {
    $scope.processComplete = () =>
      SerializeAndSubmitProcessForm('continue').catch(PromiseErrorCatcher);

    $scope.hasError = (formNames) => {
      let i = 0;

      for (i; i < formNames.length; i++) {
        const serverErrors = $scope.model.$formErrors[formNames[i]],
          form = $scope.mainForm['formly_' + formNames[i]],
          clientErrors = form && form.$invalid;
        if (clientErrors) {
          return true;
        }
        if (Object.keys(serverErrors || {}).length) {
          return angular.reduce(
            (prev, curr) =>
              (angular.isArray(prev) && prev.length) ||
              (angular.isArray(curr) && curr.length),
          );
        }
      }
      return false;
    };
  },
];

/**
 * @ngdoc component
 * @name sb.lib.administration.directive:sbMultiStepButtons
 * @require sbMultiStep
 *
 * @description
 * This is a component for the multi step directive that displays the
 * next and back buttons
 *
 * @example
   <sb-multi-step-buttons></sb-multi-step-buttons>
 */
export const sbMultiStepButtons = {
  require: {
    sbMultiStepCtrl: '^^sbMultiStep',
  },
  controllerAs: '$vm',
  template: require('./templates/buttons.html'),
};

/**
 * @ngdoc component
 * @name sb.lib.administration.directive:sbMultiStepNavigation
 * @require sbMultiStep
 *
 * @description
 * This is a component for the multi step directive
 * that displays the navigation wizard
 *
* @example
   <sb-multi-step-navigation></sb-multi-step-navigation>
 */
export const sbMultiStepNavigation = {
  require: {
    sbMultiStepCtrl: '^^sbMultiStep',
  },
  controllerAs: '$vm',
  template: require('./templates/navigation.html'),
};

/**
 * @ngdoc directive
 * @name sb.lib.administration.directive:sbMultiStep
 * @restrict EA
 *
 * @description
 * This is a directive for a multiple step component, one step
 * is displayed at a time and completed.  Once all steps are completed the complete
 * expression is evaluated.  If at any point we need to discard the wizard the revert
 * expression is also provided.
 *
 * @param {expression} sbRevertSteps Expression that needs to get called to undo any
 * work this wizard has done
 * @param {expression} sbCompleteSteps Expression that needs to be run when all steps
 * are completed.
 * @example
   <sb-multi-step
     data-sb-revert-steps="undoWork()"
     data-sb-complete-steps="saveMember()">

     <sb-multi-step-navigation></sb-multi-step-navigation>

     <sb-multi-step-step
        data-sb-step-name="Select Stakeholder">
     </sb-multi-step-step>

     <sb-multi-step-step
        data-sb-step-name="User Message">
     </sb-multi-step-step>

     <sb-multi-step-step
        data-sb-step-name="Assign Tree">
     </sb-multi-step-step>

     <sb-multi-step-buttons></sb-multi-step-buttons>

   </sb-multi-step>
 */
export const sbMultiStep = [
  function () {
    return {
      restrict: 'E',
      require: ['sbMultiStep'],
      transclude: true,
      template: require('./templates/steps.html'),
      controllerAs: '$vm',
      bindToController: true,
      scope: {
        revert: '&sbRevertSteps',
        complete: '&sbCompleteSteps',
      },
      controller: [
        '$scope',
        '$q',
        '$timeout',
        function ($scope, $q, $timeout) {
          const onSuccess = () => {
            if (this.onFinalStep()) {
              $q.when(this.complete()).catch(() => {
                // Must trigger this in the next digest cycle since forms need
                // to invalidate themselves before the steps can invalidate themselves
                $timeout(() => {
                  let earliestErrorStep;
                  angular.forEach(this.invalidSteps, (value, stepId) => {
                    const id = parseInt(stepId);
                    // XXX: THIS CODE IS UNTESTED AND I THINK INCORRECT:
                    if (
                      value &&
                      (earliestErrorStep === angular.isUndefined ||
                        earliestErrorStep > id)
                    ) {
                      earliestErrorStep = id;
                    }
                  });
                  if (earliestErrorStep !== angular.isUndefined) {
                    this.$goto(this.steps[earliestErrorStep]);
                  }
                });
              });
            } else {
              this.steps[this.currentStep].completed = true;
              this.steps[this.currentStep].linkId = this.currentStep;
              this.$goto(this.steps[this.currentStep + 1]);
            }
          };
          this.next = () => {
            $q.when(this.steps[this.currentStep].onNext()).then(onSuccess);
          };

          this.back = () => {
            const newStep = this.currentStep - 1;
            if (this.currentStep > 0) {
              this.$goto(this.steps[newStep]);
            } else {
              this.revert();
            }
          };

          this.isDisabled = () => {
            return this.invalidSteps[this.currentStep];
          };

          this.disabledReason = () => {
            return this.invalidSteps[this.currentStep]
              ? this.steps[this.currentStep].disabledMessage
              : undefined;
          };

          this.wizardGoto = (step) => {
            if (step.id < this.currentStep) {
              this.$goto(step);
            }
          };

          this.onFinalStep = () => {
            const currentStep = this.steps[this.currentStep];
            if (currentStep.isFinal) {
              return currentStep.isFinal({ total: this.steps.length });
            }
            return this.currentStep === this.steps.length - 1;
          };

          this.nextButtonName = () => {
            const currentStep = this.steps[this.currentStep];
            if (currentStep.buttonName) {
              return currentStep.buttonName;
            }
            return this.onFinalStep() ? 'Save' : 'Next';
          };

          this.$goto = (step) => {
            this.currentStep = step.id;
            this.steps = this.steps.map((item) => {
              if (item.id > this.currentStep) {
                item.completed = false;
                item.linkId = undefined;
              }
              return item;
            });
          };
        },
      ],
      link: {
        pre: (scope, element) => {
          if (!scope.$vm.currentStep) {
            scope.$vm.currentStep = 0;
          }

          scope.$vm.steps = [];
          scope.$vm.load = true;
          scope.$vm.invalidSteps = {};

          scope.$vm.$setInvalid = (stepId, value) => {
            scope.$vm.invalidSteps[stepId] = value;
          };

          scope.$vm.$register = (
            stepElement,
            name,
            buttonName,
            onNext,
            isFinal,
            disabledMessage,
          ) => {
            const index = Array.prototype.indexOf.call(
              element[0].getElementsByTagName('sb-multi-step-step'),
              stepElement[0],
            );

            scope.$vm.steps[index] = {
              completed: scope.$vm.currentStep > index,
              current: scope.$vm.currentStep === index,
              id: index,
              title: name,
              buttonName: buttonName,
              onNext: onNext ? onNext : angular.noop,
              isFinal: isFinal,
              disabledMessage: disabledMessage,
            };
            return index;
          };
        },
      },
    };
  },
];

/**
 * @ngdoc directive
 * @name sb.lib.administration.directive:sbMultiStepStep
 * @restrict EA
 *
 * @description
 * This is a directive for tags tree
 *
 * @param {expression} sbStepErrors Expression that needs to get
 * called to evaluate if the step has an error
 * @param {expression} sbStepOnNext Expression that needs to be
 * run when this step is completed.
 * @param {literal} sbStepName The name of the Step as will show
 * up in the wizard
 * @example
   <sb-multi-step-step
      sb-step-errors="hasError()"
      sb-step-on-next="next()"
      sb-step-name=""
      sb-step-button-name=""
     >
     <sb-form> ...
     </sb-form>
   </sb-multi-step-step>
 */
export function sbMultiStepStep() {
  return {
    restrict: 'E',
    require: '^^sbMultiStep',
    transclude: true,
    template: require('./templates/step.html'),
    scope: {
      errorsCall: '<sbStepErrors',
      onNext: '&?sbStepOnNext',
      name: '@sbStepName',
      buttonName: '@sbStepButtonName',
      disabledMessage: '@sbDisabledMessage',
      isFinal: '&?sbStepIsFinal',
    },
    link: (scope, element, attrs, controllers) => {
      const sbMultiStep = controllers;
      scope.$watch('errorsCall', (newValue) => {
        sbMultiStep.$setInvalid(scope.stepId, newValue);
      });
      scope.$sbMultiStep = sbMultiStep;
      scope.stepId = sbMultiStep.$register(
        element,
        scope.name,
        scope.buttonName,
        scope.onNext,
        scope.isFinal,
        scope.disabledMessage,
      );
    },
  };
}
