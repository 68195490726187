/**
 * @ngdoc object
 * @name sb.lib.auth.Auth
 *
 * @description
 * This object can create new authenication attempts and manages global
 * authentication state.
 */

export const Auth = [
  '$q',
  '$http',
  'BackendLocation',
  function ($q, $http, BackendLocation) {
    function Authentication(context) {
      context = context || 'root';
      this.$$url = BackendLocation[context](1) + 'auth';

      /**
       * @ngdoc property
       * @name $required
       * @propertyOf sb.lib.auth.Auth
       *
       * @description
       * This property is a boolean indicating that the current authentication
       * is required (as in, is the user logged in). `undefined` is initial
       * (unknown) state.
       */
      this.$required = undefined;

      /**
       * @ngdoc property
       * @name $passwordRequired
       * @propertyOf sb.lib.auth.Auth
       *
       * @description
       * This property is a boolean indicating that the current attempt still
       * needs to complete a password challenge. `undefined` is the initial
       * (unknown) state.
       */
      this.$passwordRequired = undefined;

      /**
       * @ngdoc property
       * @name $loading
       * @propertyOf sb.lib.auth.Auth
       *
       * @description
       * This property is a boolean indicating that the current attempt has
       * an outstanding load.
       */
      this.$loading = false;

      /**
       * @ngdoc property
       * @name $nextURL
       * @propertyOf sb.lib.auth.Auth
       *
       * @description
       * This property is a string, when given it should override any URL
       * to redirect to.
       */
      this.$nextURL = undefined;
    }

    /**
     * @ngdoc method
     * @name $attemptPassword
     * @methodOf sb.lib.auth.Auth
     *
     * @description
     * Complete a password challenge.
     *
     * @param {string} login ID to authenticate with.
     * @param {string} password Secrect to authenticate with.
     *
     * @returns {promise} A promise that resolves when challenge is succesfully
     *    passed. The resolved value will be a boolean indicating if the user
     *    is done authenticating (no further challenges required). The promise
     *    instead rejects when the attempt fails with a reason.
     */
    Authentication.prototype.$attemptPassword = function (login, password, nomerge) {
      this.$loading = true;
      return $http({
        url: this.$$url,
        method: 'POST',
        data: {
          sbLogin: login,
          sbPassword: password,
          sbNoMerge: nomerge,
        },
      })
        .then(
          ({ data }) => {
            this.$required = false;
            this.$passwordRequired = false;
            this.$nextURL = data.nextURL;
            return !this.$required;
          },
          ({ data, status }) => {
            if (status === -1 || status === 0) {
              this.$required = true;
              this.$passwordRequired = true;
              return $q.reject(singleton.FAILED_NETWORK);
            }
            this.$required = !data.authenticated;
            this.$nextURL = data.nextURL;

            if (status === 400 && data.reason === 'lockedOut') {
              this.$passwordRequired = true;
              return $q.reject(singleton.LOCKEDOUT);
            } else if (status === 400) {
              this.$passwordRequired = true;
              return $q.reject(singleton.BAD_PASSWORD);
            }
            this.$passwordRequired = undefined;
            this.$nextURL = undefined;
            return $q.reject(singleton.FAILED_ATTEMPT);
          },
        )
        .finally(() => {
          this.$loading = false;
        });
    };

    const singleton = {};

    /**
     * @ngdoc property
     * @name FAILED_ATTEMPT
     * @propertyOf sb.lib.auth.Auth
     *
     * @description
     * Constant string failure for failed authentication attempt.
     */
    singleton.FAILED_ATTEMPT = 'Failed to authenticate properly.';

    /**
     * @ngdoc property
     * @name BAD_PASSWORD
     * @propertyOf sb.lib.auth.Auth
     *
     * @description
     * Constant string failure for failed authentication attempt.
     */
    singleton.BAD_PASSWORD = 'That password and username do not match.';

    /**
     * @ngdoc property
     * @name LOCKEDOUT
     * @propertyOf sb.lib.auth.Auth
     *
     * @description
     * Constant string failure for failed authentication attempt.
     */
    singleton.LOCKEDOUT =
      'Your account has been locked due to excessive incorrect logins.';

    /**
     * @ngdoc property
     * @name FAILED_NETWORK
     * @propertyOf sb.lib.auth.Auth
     *
     * @description
     * Constant string failure for failed authentication attempt.
     */
    singleton.FAILED_NETWORK =
      'Unable to connect to Fidelity Private Shares. Please check your internet connection.';

    /**
     * @ngdoc method
     * @name $newAttempt
     * @methodOf sb.lib.auth.Auth
     *
     * @description
     * Create a new authentication attempt.
     *
     * @param {string} [contextName='root'] The string ID of the context.
     *
     * @returns {object} A new Authentication object for doing operations on and
     *    keeping track of an authencation attempt.
     */
    singleton.$newAttempt = function (contextName) {
      return new Authentication(contextName);
    };

    return singleton;
  },
]; // end Auth

/**
 * @ngdoc directive
 * @name sb.lib.auth.directive:sbLoginForm
 * @restrict E
 *
 * @description
 * Use this directive to insert a login form onto the page.
 *
 * @param {boolean} [sbLoginFormFocusEmail=false] Boolean if the email
 *    field should be focused on init.
 * @param {expression} [sbLoginFormOnLogin=undefined] Expression to eval when
 *    the authentication completes successfully.
 * @param {template} [sbLoginFormAuthContext='root'] Context for the
 *    authentication API. This is the name of the method that will later be used
 *    by `BackendLocation`. For instance, on a workitem, one likely wants the
 *    string `'context'`.
 * @param {template} sbLoginFormMessage HTML string to be included under
 *    as a message at the top of the form.
 * @param {template} [sbLoginFormOnLoginUrl=undefined] URL to go to on
 *    successful authentication.
 * @param {template} [sbLoginFormSignupButton=undefined] If non-empty,
 *    a create account link will be included and submit this button name.
 * @param {string} [sbLoginFormIdentifiedUser=undefined] User object,
 *     representing user that was identified by server. In case provided, user
 *     will only have to enter his password, login will be prefilled.
 * @param {string} [prefillIdentified=false] If user was previously identified,
 *     show their personal info on login screen,
 * @param {string} [confirmMerge=undefined] Force user to confirm log in if
 *     user merge is expected after login. User merge is expected identified
 *     user was not activated yet, but another user logs in.
 */
export const sbLoginForm = [
  '$animate',
  '$timeout',
  function ($animate, $timeout) {
    return {
      restrict: 'E',
      template: require('./templates/login-form.html'),
      scope: {
        focusEmail: '<sbLoginFormFocusEmail',
        onLogin: '&sbLoginFormOnLogin',
        authContext: '@sbLoginFormAuthContext',
        message: '@sbLoginFormMessage',
        signUpButton: '@sbLoginFormSignupButton',
        noAccess: '=sbLoginFormNoAccess',
        identifiedUser: '=sbLoginFormIdentifiedUser',
        prefillIdentified: '=sbLoginPrefillIdentified',
        confirmMerge: '=sbLoginConfirmMerge',
      },
      controller: [
        '$scope',
        '$window',
        '$compile',
        '$confirm',
        'Auth',
        'WindowLocation',
        'SbxUrlService',
        function (
          $scope,
          $window,
          $compile,
          $confirm,
          Auth,
          WindowLocation,
          SbxUrlService,
        ) {
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          const confirmTemplate = require('./templates/confirm-merge.html');
          const confirmText = $compile(confirmTemplate)($scope);
          this.$onInit = $onInit.bind(this);

          function $onInit() {
            $scope.welcomeIdentified =
              $scope.identifiedUser && $scope.prefillIdentified;
            // Fill in username, if we identified the user
            if ($scope.welcomeIdentified) {
              formModel.login = $scope.identifiedUser.userName;
            }

            $scope.disabledForm = true;
            $scope.auth = auth;
            $scope.formModel = formModel;
            $scope.login = login;
            $scope.getEmailsToMerge = getEmailsToMerge;
            $scope.formErrors = {};
            $scope.FORM_FIELDS = [
              {
                data: {},
                key: 'login',
                type: 'string-textline',
                templateOptions: {
                  type: 'email',
                  required: true,
                  placeholder: 'Email',
                  subfield: 0,
                },
              },
              {
                data: {},
                key: 'password',
                type: 'string-textline',
                templateOptions: {
                  type: 'password',
                  required: false,
                  placeholder: 'Password',
                  subfield: 0,
                },
              },
            ];
          }

          const auth = Auth.$newAttempt($scope.authContext),
            formModel = {};
          let onLoginUrl = new URLSearchParams($window.location.search).get('camefrom');

          function authenticate(nomerge) {
            if (nomerge === true) {
              // User decided not to merge, so it doesn't make sense to bring him
              // to the original url, because that url is supposed to be visited by
              // other stakeholder. Bring logged user to root instead.
              onLoginUrl = '/';
            }
            return auth.$attemptPassword(formModel.login, formModel.password, nomerge);
          }

          function logInWithConfirmation() {
            // We need to confirm the expected merge from user
            return $confirm({
              body: confirmText.html(),
              alertType: 'warning',
              confirmButtonText: 'Merge Accounts',
              dismissButtonText: 'No, Just Log Me In',
            }).then(
              () => authenticate(false),
              () => authenticate(true),
            );
          }

          function login() {
            $scope.error = null;
            $scope.loginFeedback
              .submittingForm()
              // make sure credentials are valid before displaying msg to merge
              // accounts
              .then(() => authenticate())
              .then((isInitialDoneAuthing) => {
                if ($scope.confirmMerge && getEmailsToMerge().length > 0) {
                  return logInWithConfirmation();
                }
                return isInitialDoneAuthing;
              })
              .then(
                (isDoneAuthing) => {
                  if (isDoneAuthing) {
                    $scope.disabledForm = true;

                    if (onLoginUrl) {
                      const camefrom = SbxUrlService.parseCamefromUrl(
                        decodeURIComponent(onLoginUrl),
                      );
                      WindowLocation.replace(camefrom);
                    } else if (auth.$nextURL) {
                      WindowLocation.replace(auth.$nextURL);
                    } else {
                      $scope.onLogin();
                    }
                  }
                },
                (error) => {
                  if (error === Auth.BAD_PASSWORD) {
                    formModel.password = '';
                    $scope.focusPassword();
                    $scope.shakeForm();
                    // XXX: why do we have here a text override???
                    $scope.error = 'Invalid login.';
                    if ($scope.confirmMerge) {
                      $scope.error +=
                        " Don't have an account? Use the sign up link below.";
                    }
                  } else if (error === Auth.LOCKEDOUT) {
                    formModel.password = '';
                    $scope.focusPassword();
                    $scope.shakeForm();
                    $scope.error = error;
                  } else if (error) {
                    $scope.error = error;
                  }
                },
              );
          }

          function getEmailsToMerge() {
            const isSameAccount =
              $scope.identifiedUser &&
              $scope.identifiedUser.userName === formModel.login;

            if (!$scope.identifiedUser || isSameAccount) {
              return [];
            }
            return [$scope.identifiedUser.userName, formModel.login];
          }
        },
      ], // end controller
      link: function (scope, element) {
        function focusPassword() {
          element.find('#innerLoginForm-password').focus();
        }
        function shakeForm() {
          $animate.addClass($shakeForm, 'shake-animation').then(() => {
            $shakeForm.removeClass('shake-animation');
          });
        }
        function updateModelsAndLogin($enterElems) {
          // Trigger input to update the models:
          $enterElems.trigger('input');
          scope.$apply(() => {
            scope.loginForm.$commitViewValue();
            scope.loginForm.$setSubmitted();
            scope.login();
          });
        }
        const $shakeForm = element.find('.login-form-block-highlight');

        $timeout(() => {
          const $button = element.find('.btn-login'),
            $password = element.find('#innerLoginForm-password'),
            $login = element.find('#innerLoginForm-login'),
            $enterElems = $password.add($login);

          if (scope.focusEmail) {
            $login.focus();
          }
          // We do not use ngClick here because we want the models to be the
          // most up to date when the user clicks (this form is often
          // autofilled and this does not trigger the normal events).
          $button.click(() => updateModelsAndLogin($enterElems));
          $enterElems.keypress((evt) => {
            // For performance reasons, we specifically do not $apply here
            // unless we need to.
            const key = evt.which;
            if (!scope.disabledForm && (key === 10 || key === 13)) {
              updateModelsAndLogin($enterElems);
            }
          });
          scope.disabledForm = false;
        }, 0);

        scope.shakeForm = shakeForm;
        scope.focusPassword = focusPassword;
      }, // end link
    };
  },
]; // end sbLoginForm
