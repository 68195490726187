import Raven from 'raven-js';
import { Inject, Injectable, NgZone } from '@angular/core';
import {
  EntityInfoResponse,
  LensInfoNameEnum,
  SPAconfigResponse,
  UserInfoResponse,
  WebSocketInfo,
} from '@shoobx/types/webapi-v2';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Downgrade } from '@/shared/downgrade';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { SbxZendeskWidget } from '../zendesk/zendesk.service';
import { catchError, EMPTY } from 'rxjs';
import { handleRedirect } from '../http/client.service';
import { APP_BASE_HREF } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
@Downgrade.Injectable('ngShoobx', 'AppConfig')
export class AppConfig {
  private BASE_URL = `${location.origin}/api/2/spa`;
  private initialConfig: SPAconfigResponse = null;
  authenticated = false;
  onbehalf = null;
  rootConfig: SPAconfigResponse & EntityInfoResponse = null;
  config: SPAconfigResponse & EntityInfoResponse = null;
  config$ = new BehaviorSubject<SPAconfigResponse & EntityInfoResponse>(null);
  userInfo: UserInfoResponse = null;
  userInfo$ = new BehaviorSubject<UserInfoResponse>(null);
  bootstrapped$ = new BehaviorSubject(false);

  get sideMenu() {
    return this.config?.sideMenu || this.initialConfig?.sideMenu;
  }

  get currentEntity() {
    if (
      !this.userInfo?.currentStake?.name ||
      this.userInfo?.currentStake?.name === '++shoobx'
    ) {
      return;
    }

    return this.userInfo.currentStake;
  }

  get currentLens() {
    return this.userInfo?.lenses?.find(({ onbehalf, name }) => {
      return onbehalf === this.onbehalf && name === this.userInfo.currentLens;
    });
  }

  get userProfile() {
    return this.userInfo?.profile;
  }

  get endpointInfos() {
    return this.config.endpointInfos;
  }

  get webSocket(): WebSocketInfo {
    return this.userInfo?.webSocket || this.config?.webSocket;
  }

  constructor(
    @Inject(HttpClient) private httpClient: HttpClient,
    @Inject(Router) private router: Router,
    @Inject(APP_BASE_HREF) private baseHref: string,
    @Inject(SbxZendeskWidget) private sbxZendeskWidget: SbxZendeskWidget,
    private zone: NgZone,
  ) {
    // Here we're exposing angular zone so Selenium could drive smoke test
    // with external browser on integration environment that has production code.
    //
    // The only real user of this is at time of writing is
    // shoobx.app.ftesting.browser.api:ShoobxTestingAPI.waitForPage
    // Ideally we'd write another simpler implementation of waitForPage javascript
    // side we could use for somke test, even if it is not stable enough to run
    // all browser tests.
    // eslint-disable-next-line dot-notation
    window['tzn'] = this.zone;
    // Expose angular router to testing-scripts
    // eslint-disable-next-line dot-notation
    window['router'] = this.router;
    // eslint-disable-next-line dot-notation
    window['bootstrapped$'] = this.bootstrapped$;
  }

  async initialize() {
    await this.initialConfigInit();

    // Header and footer are Angular components. Zope page loads before
    // Angular, thus leaving white gap where header and footer should be until
    // angular loads. This hides page content until angular is loaded to make
    // loading more streamlined.
    setTimeout(
      () =>
        document
          .querySelector('[data-hide-until-bootstrap-ng2]')
          ?.removeAttribute('data-hide-until-bootstrap-ng2'),
    );
  }

  async initialConfigInit() {
    try {
      // Lets fetch initial config
      const configUrl = `${this.BASE_URL}/config`;
      const configResponse = await this.httpClient
        .get<SPAconfigResponse>(configUrl, {
          observe: 'response',
          headers: this.getHeaders(),
        })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (
              handleRedirect(error.status, {
                redirectUrl: error.error.redirectUrl,
                entityName: null,
              })
            ) {
              return EMPTY;
            }

            throw error.error;
          }),
        )
        .toPromise();

      this.initialConfig = configResponse.body;
      this.config = configResponse.body;
      this.config$.next(this.config);

      if (this.initialConfig.ravenInfo.url) {
        Raven.config(this.initialConfig.ravenInfo.url, {
          release: this.initialConfig.ravenInfo.release,
        }).install();
      }

      // Check x-user-id header, if its none, then user is not logged in
      if (configResponse.headers.get('x-user-id') === 'none') {
        const { entityName, baseName } = this.parseAnonymusEntityAndBaseFromUrl(
          location.href,
        );
        await this.rootEntityInit(entityName, baseName);
        return;
      }

      // Lets fetch initial user config
      await this.userInit();

      // Check entity name in url
      const entityNameInUrl = this.parseEntityNameFromUrl(location.href);
      if (!entityNameInUrl) {
        return;
      }

      // Fetch and set entity info
      await this.entityInit(entityNameInUrl);
    } catch (e) {}
  }

  reset() {
    this.config = this.initialConfig;
    this.config$.next(this.config);
    this.userInfo = null;
    this.onbehalf = null;
    this.userInfo$.next(this.userInfo);
    this.authenticated = false;
    this.toggleZendesk();
    this.removeEntityThemeCss();
  }

  async userInit() {
    const url = `${this.BASE_URL}/userinfo`;
    const response = await this.httpClient
      .get<UserInfoResponse>(url, { headers: this.getHeaders() })
      .toPromise();

    this.userInfo = response;

    Raven.setUserContext({ userId: this.userInfo.ravenInfo.userId });

    this.authenticated = true;
  }

  async rootEntityInit(entity: string, base?: string) {
    const url = `${this.BASE_URL}/${entity}/entityinfo`;
    const headers = this.getHeaders(base ? { 'X-SB-Base': base } : {});

    this.rootConfig = await this.httpClient
      .get<EntityInfoResponse>(url, { headers })
      .toPromise();
    this.config.endpointInfos = this.rootConfig.endpointInfos;
  }

  async entityInit(entity: string, onbehalf?: string, lens?: LensInfoNameEnum) {
    const url = `${this.BASE_URL}/${entity}/entityinfo`;

    const {
      userInfo: entityUserInfo,
      redirectUrl,
      ...entityInfo
    } = await this.httpClient
      .get<EntityInfoResponse>(url, { headers: this.getHeaders() })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (
            handleRedirect(error.status, {
              nextUrl: error.error.redirectUrl,
              entityName: this.parseEntityNameFromUrl(error.url),
            })
          ) {
            return EMPTY;
          }
          throw error.error;
        }),
      )
      .toPromise();
    this.config = {
      ...this.initialConfig,
      ...entityInfo,
    };
    this.config$.next(this.config);
    this.userInfo = entityUserInfo;
    this.userInfo$.next(this.userInfo);
    const onbehalfName = onbehalf || entityInfo.endpointInfos['2'].onbehalf;
    const lensName = lens || (this.userInfo.currentLens as LensInfoNameEnum);
    this.injectEntityThemeCss(entityInfo.entityInfo.themeCss);
    this.changeLens(onbehalfName, lensName);
    this.toggleZendesk();

    // We should ignore redirect if we are in TFA page, otherwise we will be in infinite loop
    if (
      redirectUrl &&
      window.location.href !== redirectUrl &&
      !window.location.href.includes('/tfa')
    ) {
      window.location.replace(redirectUrl);
    }
  }

  getHeaders(headers = {}) {
    const xSbOnbehalf = this.parseOnbehalfFromUrl(window.location.href);

    if (xSbOnbehalf) {
      return {
        ...headers,
        'X-SB-Onbehalf': this.parseOnbehalfFromUrl(window.location.href),
      };
    }

    return headers;
  }

  public parseAnonymusEntityAndBaseFromUrl(url) {
    const pathName = new URL(url).pathname;
    if (!pathName.includes('/onbehalf-anonymous')) {
      return;
    }

    const paths = pathName.split('/').filter((i) => i);
    const baseName = paths[1].replace('++base+', '');
    const entityName = paths[2];

    if (entityName === 'process') {
      // start url dumps the user into a process which gets parsed as the
      // entity in RootEntityInit.
      return;
    }

    return {
      entityName,
      baseName,
    };
  }

  public parseOnbehalfFromUrl(url) {
    return url.match(/\/onbehalf-(.*?)(?:\/|$)/)?.[1];
  }

  public parseEntityNameFromUrl(url) {
    const pathName = new URL(url).pathname;

    if (pathName.includes('/onbehalf-')) {
      const onbehalfRegexp = /\/onbehalf-.*?\/((\w|-)*)/;
      const onbehalfMatch = pathName.match(onbehalfRegexp)?.[1];

      if (onbehalfMatch) {
        return onbehalfMatch;
      }
      return;
    }

    if (pathName.includes(this.baseHref)) {
      const spaRegexp = /\/spa\/((\w|-)*)/;
      const spaMatch = pathName.match(spaRegexp)?.[1];

      if (spaMatch && !this.router.config.map((r) => r.path).includes(spaMatch)) {
        return spaMatch;
      }
      return;
    }

    return pathName
      .split('/')
      .filter((i) => i)
      .filter((i) => !i.includes('.html') && !i.includes('impersonate'))[0];
  }

  changeLens(onbehalf: string, name: LensInfoNameEnum) {
    this.onbehalf = onbehalf;
    this.userInfo = { ...this.userInfo, currentLens: name };
    this.userInfo$.next(this.userInfo);
  }

  toggleZendesk() {
    const showZendesk = this.config.entityInfo?.showZendesk;
    const inIfarme = location.href.indexOf('bare-process') !== -1;

    if (showZendesk && !inIfarme) {
      this.sbxZendeskWidget.show();
    } else {
      this.sbxZendeskWidget.hide();
    }
  }

  injectEntityThemeCss(styleStr: string) {
    const styleEl = document.createElement('style');
    styleEl.textContent = styleStr;
    styleEl.classList.add('sbx-entity-theme-css');
    document.head.appendChild(styleEl);
  }

  removeEntityThemeCss() {
    const styleEl = document.querySelector('.sbx-entity-theme-css');
    if (styleEl) {
      styleEl.parentNode.removeChild(styleEl);
    }
  }

  resetCurrentEntity() {
    this.userInfo.currentStake = null;
  }
}
