/**
 * @ngdoc component
 * @name sb.lib.sharing.component:sbBulkSharingBtn
 *
 * @description
 * This displays a bulk sharing modalx``x
 *
 */
export const sbBulkSharingBtn = {
  controllerAs: 'vm',
  template: require('./templates/bulk-sharing-btn.html'),
  bindings: {
    stakeholderId: '<',
    callBack: '&?',
    title: '@?',
    stakeholderName: '@',
    defaultLevel: '@',
    icon: '@?',
    modalTitle: '@?',
  },
  controller: [
    '$scope',
    '$sbModal',
    'PromiseErrorCatcher',
    function ($scope, $sbModal, PromiseErrorCatcher) {
      function openModal() {
        const modal = $sbModal.open({
          size: 'lg',
          keyboard: false,
          backdrop: 'static',
          template: require('./templates/bulk-sharing-modal.html'),
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            stakeholderName: () => this.stakeholderName,
            stakeholderId: () => this.stakeholderId,
            defaultLevel: () => this.defaultLevel,
            title: () => this.modalTitle,
          },
          controller: BulkSharingModalCtrl,
        });
        modal.result.catch(PromiseErrorCatcher);
        if (this.callBack) {
          modal.closed.then(this.callBack);
        }
      }
      this.$onInit = () => {
        this.title = this.title || 'Edit Document Sharing';
        this.defaultLevel = this.defaultLevel || 'share';
        this.openModal = openModal.bind(this);
        this.icon = this.icon || 'fa fa-edit';
        this.modalTitle = this.modalTitle || null;
      };
    },
  ],
};

export const BulkSharingModalCtrl = [
  'stakeholderId',
  'stakeholderName',
  'defaultLevel',
  'title',
  'SharingService',
  function (stakeholderId, stakeholderName, defaultLevel, title, SharingService) {
    this.loading = true;
    this.permissionOptions = SharingService.getVisiblePermissionOptions(stakeholderId);
    this.stakeholderId = stakeholderId;
    this.stakeholderName = stakeholderName;
    this.defaultLevel = defaultLevel;
    this.title = title;
    this.permissionOptions.then((data) => {
      this.loading = false;
      this.folders = data.folders;
      this.sharingForm = data.sharingForm;
      this.model = data.folders
        .reduce((folders, folder) => {
          return folders.concat(folder);
        })
        .reduce((accumulator, current) => {
          const permission = data.permissions.find((item) => item.id === current.id);
          accumulator[current.id] = permission || { id: current.id };
          return accumulator;
        }, {});
      this.openNodeIds = data.permissions.map((item) => item.id);
    });

    function saveGrants() {
      const grants = Object.keys(this.model).map((key) => this.model[key]);

      SharingService.saveBulkGrants(grants, this.stakeholderId).then(this.$dismiss);
    }

    this.saveGrants = saveGrants.bind(this);
  },
];

export const sbBulkSharingLevel = {
  controllerAs: 'vm',
  template: require('./templates/bulk-sharing-level.html'),
  bindings: {
    model: '=',
    defaultLevel: '@',
    form: '<',
    title: '@',
    totalDocumentCount: '<?',
    disabled: '<?',
    indent: '<',
    indentLevel: '@',
    isOpen: '<',
    toggle: '&',
    grants: '<',
    hasChildren: '<',
    isParentOf: '&',
    shareable: '<?',
    onChangeHook: '&?',
  },
  controller: [
    function () {
      function onChange(val) {
        if (val) {
          this.model.level = this.defaultLevel;
        } else {
          this.model.expires = undefined;
          this.model.level = undefined;
        }
        if (this.onChangeHook) {
          this.onChangeHook({ value: val, shareable: this.shareable });
        }
      }
      this.$onInit = () => {
        this.levelStyle = { 'padding-left': 20 * this.indent };
        this.rootElement = this.indent === 0;
        if (this.rootElement && !this.isOpen) {
          this.toggle();
        }

        this.checked = Boolean(this.model.level);
        this.isColored = () => {
          // Grants are all the selected access grants these objects can change
          // over the life time of the tree.  The object references will not however
          // their levels will.
          const grants = Object.keys(this.grants).map((key) => this.grants[key]);
          return Boolean(
            grants.find(
              (item) =>
                (item.level || item.existingGrants) &&
                this.isParentOf({ $parentId: item.id }),
            ) ||
              this.model.existingGrants ||
              this.model.level,
          );
        };
        this.havePopover = () => {
          return this.isColored() && !this.model.level;
        };
        this.popoverText = () => {
          if (this.model.existingGrants) {
            const unique = (value, index, self) => {
              return self.indexOf(value) === index;
            };

            const reasons = this.model.existingGrants
              .map((grant) => {
                if (grant.byTag && grant.viaGroupUiGroup === 'companyRoles') {
                  return `the role <strong>${grant.viaGroupTitle}</strong>`;
                } else if (grant.byTag) {
                  return `company permission for <strong>${grant.viaGroupTitle}</strong>`;
                }
                return (
                  `${grant.levelTitle}</strong> rights granted ` +
                  `to group <strong>${grant.viaGroupTitle}</strong>`
                );
              })
              .filter(unique)
              .join(', ');
            return `This user has been authorized to access this folder via: ${reasons}.`;
          }
          return "This folder's permissions will be inherited.";
        };
        this.levelField = this.form.fields.find((fld) => fld.key === 'level');
        this.onChange = onChange.bind(this);
      };
    },
  ],
};

export const sbBulkSharingTable = {
  controllerAs: 'vm',
  template: require('./templates/bulk-sharing-table.html'),
  bindings: {
    folders: '<',
  },
};
