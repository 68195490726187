import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'sbx-checkbox',
  templateUrl: './sbx-checkbox.component.html',
  styleUrls: ['./sbx-checkbox.component.scss'],
})
export class SbxCheckboxComponent implements OnInit {
  @Input() readOnly;
  @Input() model!: boolean;
  @Input() indeterminate?: boolean;
  @Input() label: string = null;
  @Input() name: string;
  @Output() change = new EventEmitter();
  @Output() focus = new EventEmitter();
  @HostBinding('class.sbx-block-container') withLabel = false;

  ngOnInit() {
    this.withLabel = Boolean(this.label);
  }

  toggleValue() {
    this.change.emit(!this.model);
  }

  handleFocus($event) {
    this.focus.emit($event);
  }

  handleKeyDown(event) {
    // Enter
    if (event.which === 13) {
      this.toggleValue();
    }
  }
}
