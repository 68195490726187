<div
  class="sbx-checkbox"
  tabindex="0"
  [attr.name]="name"
  (click)="readOnly ? null : toggleValue()"
  (focus)="handleFocus($event)"
  [ngClass]="{ disabled: readOnly }"
  [attr.checked]="model"
  (keydown)="handleKeyDown($event)"
>
  <div class="sbx-icon-container">
    <sbx-icon
      tabindex="-1"
      *ngIf="!indeterminate && model"
      type="check"
      (focus)="handleFocus($event)"
    ></sbx-icon>
    <sbx-icon
      tabindex="-1"
      *ngIf="indeterminate"
      type="minus"
      class="sbx-checkbox-indeterminate"
      (focus)="handleFocus($event)"
    ></sbx-icon>
  </div>
  <div class="sbx-label" *ngIf="label">{{ label }}</div>
</div>
