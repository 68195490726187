import angular from 'angular';
import { List, Map } from 'immutable';

/**
 * @ngdoc service
 * @name sb.process.ProcessStatus
 *
 * @description
 * This service contains the current state of the process "status" bar.
 */
export const ProcessStatus = [
  '$q',
  '$exceptionHandler',
  '$window',
  function ($q, $exceptionHandler, $window) {
    const GENERIC_PROCESS_ERROR =
      'An error occurred and has been reported to Fidelity Private Shares support.';
    return {
      /**
       * @ngdoc property
       * @name $open
       * @propertyOf sb.process.ProcessStatus
       *
       * @description
       * Boolean indicating if the status bar is open.
       */
      $open: false,

      /**
       * @ngdoc property
       * @name $content
       * @propertyOf sb.process.ProcessStatus
       *
       * @description
       * String content of the process status.
       */
      $content: '',

      /**
       * @ngdoc property
       * @name $buttonText
       * @propertyOf sb.process.ProcessStatus
       *
       * @description
       * String content of the process status "accept" button.
       */
      $buttonText: '',

      /**
       * @ngdoc property
       * @name $cancelButtonText
       * @propertyOf sb.process.ProcessStatus
       *
       * @description
       * String content of the process status "cancel" button.
       */
      $cancelButtonText: '',

      /**
       * @ngdoc property
       * @name $type
       * @propertyOf sb.process.ProcessStatus
       *
       * @description
       * String type of the current status.
       */
      $type: '',

      /**
       * @ngdoc property
       * @name $action
       * @propertyOf sb.process.ProcessStatus
       *
       * @description
       * Bool indicating if this status has an action.
       */
      $action: false,

      /**
       * @ngdoc method
       * @name $confirmAction
       * @methodOf sb.process.ProcessStatus
       *
       * @description
       * Function to resolve the status promise.
       */
      $confirmAction: angular.noop,

      /**
       * @ngdoc method
       * @name $cancelAction
       * @methodOf sb.process.ProcessStatus
       *
       * @description
       * Function to reject the status promise.
       */
      $cancelAction: angular.noop,

      /**
       * @ngdoc method
       * @name $setStatus
       * @methodOf sb.process.ProcessStatus
       *
       * @description
       * Set the status of the process.
       *
       * @param {string} [content=undefined] Wording of the status. A call to this
       *    method without this parameter means close the status bar (*NOTE*:
       *    closing the status does not change the underlying data).
       * @param {string} [type='warning'] Type of status.
       */
      $setStatus: function (content, type) {
        if (!content) {
          this.$open = false;
          return;
        }
        this.$open = true;
        if (angular.isString(content)) {
          this.$content = content;
        } else {
          // This means a status was (incorrectly) set to something other than a
          // string. Most likely, we caught an exception in a promise chain with
          // `.catch()` but assumed it was just an error string and not a full
          // exception.
          this.$content = GENERIC_PROCESS_ERROR;
          $exceptionHandler(content, `Bad error string on ${$window.location.href}`);
        }
        this.$type = type || 'warning';
        this.$action = false;
      },

      /**
       * @ngdoc method
       * @name $setAction
       * @methodOf sb.process.ProcessStatus
       *
       * @description
       * Sets the status to open with an action.
       *
       * @param {string} content Wording of the action status.
       * @param {string} buttonText String of button
       * @param {string} [type='warning'] Type of status.
       * @param {string} [cancelButtonText=undefined] If defined, action will
       *    have a "cancel" button which will reject the action.
       *
       * @returns {promise} Resolves when the action is confirmed and rejects
       *    when the action is canceled.
       */
      $setAction: function (content, buttonText, type, cancelButtonText) {
        this.$open = true;
        this.$content = content;
        this.$type = type || 'warning';
        this.$action = true;
        this.$buttonText = buttonText;
        this.$cancelButtonText = cancelButtonText || '';
        return $q((resolve, reject) => {
          this.$confirmAction = resolve;
          this.$cancelAction = reject;
        });
      },
    };
  },
]; // end ProcessStatus

/**
 * @ngdoc object
 * @name sb.process:ProcessStatusModel
 *
 * @description
 * A model for an process status models. See init() for full listing of properties.
 */
export const ProcessStatusModel = [
  '$q',
  '$window',
  'BackendLocation',
  'SimpleHTTPWrapper',
  '$workitemRecord',
  'ProcessModalUrlService',
  function (
    $q,
    $window,
    BackendLocation,
    SimpleHTTPWrapper,
    $workitemRecord,
    ProcessModalUrlService,
  ) {
    class Model {
      constructor(procId, disableDiscard) {
        this._url = `${BackendLocation.entity(1)}process/${procId}/`;

        /**
         * @ngdoc property
         * @name id
         * @propertyOf sb.process.ProcessStatusModel
         *
         * @description
         * String id of the process.
         */
        this.id = procId;

        /**
         * @ngdoc property
         * @name disableDiscard
         * @propertyOf sb.process.ProcessStatusModel
         *
         * @description
         * Force suppression of the Discard button.
         */
        this.disableDiscard = disableDiscard;

        /**
         * @ngdoc property
         * @name loading
         * @propertyOf sb.process.ProcessStatusModel
         *
         * @description
         * Loading boolean for aysnc actions.
         */
        this.loading = false;

        /**
         * @ngdoc property
         * @name docsLoading
         * @propertyOf sb.process.ProcessStatusModel
         *
         * @description
         * Loading boolean for loading all documents.
         */
        this.docsLoading = false;

        /**
         * @ngdoc property
         * @name status
         * @propertyOf sb.process.ProcessStatusModel
         *
         * @description
         * Object with type and text and/or null for setting modal status bar
         */
        this.status = null;
      }

      _loading(prom) {
        this.loading = true;
        this.status = null;
        return prom
          .catch(() => {
            this.setStatus('danger', 'Error loading process information');
            return $q.reject();
          })
          .finally(() => {
            this.loading = false;
          });
      }

      _loadingDocs(prom) {
        this.docsLoading = true;
        this.status = null;
        return prom
          .catch(() => {
            this.setStatus('danger', 'Error loading documents');
            return $q.reject();
          })
          .finally(() => {
            this.docsLoading = false;
          });
      }

      /**
       * @ngdoc method
       * @name setStatus
       * @methodOf sb.process.ProcessStatusModel
       * @param {string} type
       * @param {string} text
       */
      setStatus(type, text) {
        this.status = { type, text };
      }

      /**
       * @ngdoc method
       * @name changeProcessOwner
       * @methodOf sb.process.ProcessStatusModel
       * @param {string} stakeholderId
       *
       * @returns {promise} Resolves/rejects on success and failure respectively.
       */
      changeProcessOwner(stakeholderId) {
        this.$changeProcessOwnerLoading = true;
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: this._url + 'process-driver',
            data: { stakeholderId },
          },
          'Could not change workflow owner.',
        ).then((data) => {
          this.processDriver = data.processDriver;
          this.workitems = List(data.workitems.map($workitemRecord));
          this.$changeProcessOwnerLoading = false;
        });
      }

      /**
       * @ngdoc method
       * @name discardProcess
       * @methodOf sb.process.ProcessStatusModel
       *
       * @returns {promise} Navigates to abort process page
       */
      discardProcess() {
        this.loading = true;
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: this._url + 'state',
            params: { returnUrl: encodeURIComponent($window.location.href) },
          },
          'Cannot discard process.',
        )
          .then((data) => {
            if (data.canDiscard) {
              ProcessModalUrlService.goToUrl(data.abortUrl);
            } else {
              this.setStatus('danger', 'You can no longer discard this workflow.');
              this.loading = false;
            }
          })
          .catch(() => {
            this.setStatus('danger', 'Error loading process information');
            return $q.reject();
          });
      }

      getAllDocs() {
        const prom = SimpleHTTPWrapper(
          {
            url: this._url,
            params: {
              withDocuments: true,
              processDocsOnlyDRVisible: true,
            },
          },
          'Could not load all documents.',
        ).then((data) => {
          this.documents = List(data.documents.map(Map));
          this.allDocs = data.allDocs;
        });
        return this._loadingDocs(prom);
      }

      /**
       * @ngdoc property
       * @name init
       * @methodOf sb.process.ProcessStatusModel
       *
       * @description
       * Call this to gather data.
       *
       * @returns {promise} Resolves/rejects on success and failure respectively.
       */
      init() {
        const prom = SimpleHTTPWrapper(
          {
            url: this._url,
            params: {
              withWorkitems: true,
              withProcessInfo: true,
              withWorkitemWaitInfo: true,
              withDocuments: true,
              processDocsOnlyDRVisible: true,
              limitDocs: 25,
              withEmails: true,
            },
          },
          'Could not load process status.',
        ).then((data) => {
          this.url = data.url;
          this.isDiscardable = data.isDiscardable;
          this.showDiscardButton = data.showDiscardButton && !this.disableDiscard;
          this.title = data.title;
          this.emails = List(data.emails.map(Map));
          this.startedBy = data.startedBy;
          this.startedOn = data.startedOn;
          this.processDriver = data.processDriver;
          this.canChangeDriver = data.canChangeDriver;
          this.documents = List(data.documents.map(Map));
          this.allDocs = data.allDocs;
          this.workitems = List(data.workitems.map($workitemRecord));
          this.info = List(data.info.map(Map));
          this.isFinished = data.isFinished;
          this.finishedOn = data.finishedOn;
          this.securityTicketID = data.securityTicketID;
        });
        return this._loading(prom);
      }
    }
    return (procid, disableDiscard) => new Model(procid, disableDiscard);
  },
]; // end ProcessStatusModel

/**
 * @ngdoc controller
 * @name sb.process.controller:ProcessStatusWidgetCtrl
 *
 * @description
 * This is a private controller for the process status modal.
 */
export const ProcessStatusWidgetCtrl = [
  'ProcessStatusModel',
  '$confirm',
  'AppConfig',
  '$formModal',
  'PromiseErrorCatcher',
  function (ProcessStatusModel, $confirm, AppConfig, $formModal, PromiseErrorCatcher) {
    function openEmail(evt, email) {
      evt.preventDefault();
      $confirm({
        title: email.get('subject'),
        body: email.get('content').replace(/href="(.*?)"/g, ''),
        confirmButtonText: false,
        dismissButtonText: 'Done',
      }).catch(PromiseErrorCatcher);
    }
    function openChangeProcessOwnerModal() {
      const changeOwnerFields = [
        {
          key: 'stakeholder',
          type: 'stakeholder',
          templateOptions: {
            required: true,
            label: 'New Workflow Owner',
            securityTicketID: this.model.securityTicketID,
            stakeholderOptions: {
              allowExisting: true,
              allowEntity: true,
              allowPerson: true,
              format: 'medium',
              entityOptions: {},
              disableCreate: true,
              pickRepresentative: true,
              pickRepresentativeOnly: true,
            },
          },
          data: {},
        },
      ];
      $formModal({
        title: 'Change Workflow Owner',
        htmlContent: require('./templates/change-owner-modal.html'),
        windowClass: 'change-process-owner',
        controllerAs: 'vm',
        forms: { changeOwner: { fields: changeOwnerFields } },
        formData: { changeOwner: { stakeholder: null } },
        loading: () => this.model.$changeProcessOwnerLoading,
        onConfirmPromise: ({ $formData }) => {
          if ($formData.changeOwner.stakeholder) {
            this.model.$changeProcessOwnerLoading = true;
            return this.model
              .changeProcessOwner($formData.changeOwner.stakeholder.sh.id)
              .then(() => {
                this.workflowOwnerChanged = true;
                this.refresh();
              })
              .catch(PromiseErrorCatcher);
          }
        },
      }).catch(PromiseErrorCatcher);
    }

    function onButtonSuccess(data) {
      this.model.init().catch(PromiseErrorCatcher);
      this.model.setStatus('success', data);
    }

    this.$onInit = () => {
      const currentPrinId = AppConfig.userProfile.id;
      this.workflowOwnerChanged = false;
      this.isCurrentUser = ({ id }) => currentPrinId === id;
      this.openEmail = openEmail.bind(this);
      this.onButtonSuccess = onButtonSuccess.bind(this);
      this.onDocTabClick = () => this.model.getAllDocs().catch(PromiseErrorCatcher);
      this.openChangeProcessOwnerModal = openChangeProcessOwnerModal.bind(this);
      this.model = ProcessStatusModel(this.processId, this.disableDiscard);
      this.model.init().catch(PromiseErrorCatcher);
      this.refresh = () => this.model.init().catch(PromiseErrorCatcher);
    };
  },
]; // end ProcessStatusWidgetCtrl

/**
 * @ngdoc component
 * @name sb.process.component:sbProcessStatusWidget
 *
 * @description
 * This component will display the "status" of a modal, showing current blockers, sent
 * emails, documents etc.
 *
 * @param {template} processId The ID of the process to show the status of.
 * @param {expression} [onClose=undefined] If defined, component will include a close
 *   button and this will evaluate this expression on click.
 * @param {template} disableDiscard Whether the discard button should be suppressed.
 */
export const sbProcessStatusWidget = {
  template: require('./templates/status-widget.html'),
  controllerAs: 'vm',
  bindings: {
    processId: '@',
    disableDiscard: '=',
    onClose: '&?',
  },
  controller: ProcessStatusWidgetCtrl,
}; // end sbProcessStatusWidget

/**
 * @ngdoc component
 * @name sb.process.component:sbWorkitemStatusTooltip
 *
 * @description
 * This component will display the button of showing "status" of workitems
 * under a process as a tooltip. Transclusion is used to allow customization
 * of the tooltip button's icon and text.
 *
 * @param {template} processId The ID of the process to show the status of.
 * @param {template} [message=undefined] A contextual message showing up as the
 * title of the popover
 * @param {expresssion} [onClose=undefined] If defined, component will include
 * a close button and this will evaluate this epression on click.
 * @example
     <sb-workitem-status-tooltip process-id="123" message="Some String">
       <i class="fa fa-list"></i> Details
     </sb-workitem-status-tooltip>
 */
export const sbWorkitemStatusTooltip = {
  template: require('./templates/workitem-status-tooltip.html'),
  controllerAs: 'vm',
  transclude: true,
  bindings: {
    processId: '@',
    message: '@?',
    onClose: '&?',
  },
  controller: [
    'ProcessStatusModel',
    '$compile',
    '$scope',
    'PromiseErrorCatcher',
    function (ProcessStatusModel, $compile, $scope, PromiseErrorCatcher) {
      // the original click event triggered by the button click
      let buttonOrignalEvent;

      this.handleDetailButtonClick = ({ originalEvent }) => {
        buttonOrignalEvent = originalEvent;
        this.shouldShowPopover = !this.shouldShowPopover;
        if (this.shouldShowPopover && !this.model) {
          this.model = ProcessStatusModel(this.processId);
          this.model.init().catch(PromiseErrorCatcher);
        }
      };

      this.handleSbClickOutside = ({ originalEvent }) => {
        // Preventing the bubbled up clickOutside event to close the opened
        // popover, if they are the same event.
        if (buttonOrignalEvent && buttonOrignalEvent === originalEvent) {
          return;
        }
        this.shouldShowPopover = false;
      };

      this.$onInit = () => {
        this.content = angular.element(`
          <div sb-click-outside="vm.handleSbClickOutside($event)">
            <div class="angular-no-animate" ng-if="vm.model && vm.model.loading"
            style="height: 200px; display: block; width: 450px;">
              <sbx-icon type="spinner"></sbx-icon>
            </div>
            <sb-workitem-status-list ng-if="vm.model && !vm.model.loading"
              sb-scrollable style="height: 200px; display: block; width: 450px;"
              workitems="::vm.model.workitems"></sb-workitem-status-list>
          </div>
      `);
        this.title = angular.element(`
          <div>{{ vm.message }}</div>
      `);
        $compile(this.content)($scope);
        $compile(this.title)($scope);
        this.shouldShowPopover = false;
      };
    },
  ],
}; // end sbWorkitemStatusTooltip

/**
 * @ngdoc component
 * @name sb.process.component:sbWorkitemStatusList
 *
 * @description
 * This component will display the status for a list of workitems that are
 *   currently in process.
 *
 * @param {Immutable.List<Workitem>} workitems A list of standard $workitem
 * records.
 * @param {expression} [onWorkitemRemindSuccess=undefined] Expression evaluated on
 *   successful workitem remind request (e.g., from sbWorkitemRemindButton).
 *   `$data` is available in namespace.
 * @param {expression} [onInviteSuccess=undefined] Expression evaluated on
 *   successful stakeholder invite request (e.g., from sbInviteButton).
 * @param {expression} [onWorkitemClaimSuccess=undefined] Expression evaluated on
 *   successful workitem claim request (e.g., from sbWorkitemClaimButton).
 *   `$data` is available in namespace.
 */
export const sbWorkitemStatusList = {
  template: require('./templates/workitem-status-list.html'),
  controllerAs: 'vm',
  bindings: {
    workitems: '<',
    onWorkitemRemindSuccess: '&?',
    onInviteSuccess: '&?',
    onWorkitemClaimSuccess: '&?',
  },
}; // end sbWorkitemStatusList
