/**
 * Form controllers
 */

angular.module('ngShoobx').controller('MultiWidgetCtrl', [
  '$scope',
  'SerializeAndSubmitProcessForm',
  'PromiseErrorCatcher',
  function ($scope, SerializeAndSubmitProcessForm, PromiseErrorCatcher) {
    $scope.submit = function (evt, buttonName) {
      var $form = angular.element(evt.target.form);
      if (!$form.is('.process > form')) return;
      SerializeAndSubmitProcessForm(buttonName, {}, true).catch(PromiseErrorCatcher);
    };
  },
]); // end MultiWidgetCtrl
