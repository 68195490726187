import { InjectionToken } from '@angular/core';

type IType = 'success' | 'info' | 'info-white' | 'warning' | 'danger';

export interface ISbxProcessStatus {
  $open: false;
  $content: string;
  $buttonText: string;
  $cancelButtonText: string;
  $type: string;
  $action: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  $confirmAction: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  $cancelAction: Function;
  $setStatus: (content: string, type: IType) => void;
  $setAction: (
    content: string,
    buttonText: string,
    type: IType,
    cancelButtonText: string,
  ) => void;
}

export const SbxProcessStatus = new InjectionToken<ISbxProcessStatus>(
  'SbxProcessStatus',
);

export function ProcessStatusServiceFactory(i: any) {
  return i.get('ProcessStatus');
}

export const ProcessStatusServiceProvider = {
  provide: SbxProcessStatus,
  useFactory: ProcessStatusServiceFactory,
  deps: ['$injector'],
};
