import { Downgrade } from '@/shared/downgrade';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { formatNumber } from '@angular/common';

const NUMBER_REGEX = /[^\d]/g;
const FLOAT_REGEX = /[^\d.-]/g;

@Downgrade.Component('ngShoobx', 'sbx-number-textline-base')
@Component({
  selector: 'sbx-number-textline-base',
  templateUrl: './sbx-number-textline-base.component.html',
  styleUrls: ['./sbx-number-textline-base.component.scss'],
})
export class SbxNumberTextlineBaseComponent implements OnInit {
  @ViewChild('inputField', { static: true }) input;
  @Input() model: any;
  @Input() id: string;
  @Input() min: number;
  @Input() max: number;
  @Input() precision = 0;
  @Input() errorsMin = false;
  @Input() errorsMax = false;
  @Input() showError = false;
  @Input() readOnly = false;
  @Input() to: any;
  @Output() changeValue = new EventEmitter();
  @Output() change = new EventEmitter();
  @Output() focus = new EventEmitter<FocusEvent>();
  @Output() blur = new EventEmitter<FocusEvent>();

  formattedValue = '';

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    if (!this.precision) {
      this.precision = 0;
    }
    this.formatValue(`${this.model}`);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.model?.firstChange ||
      changes.model?.previousValue === changes.model?.currentValue
    ) {
      return;
    }

    let value = `${this.model}`;
    if (Object.is(this.model, -0)) {
      // special case for negative zero where stringification removes the negative
      value = '-' + value;
    }

    this.formatValue(value, true);
  }

  formatValue(value, suppressChangeValue = false) {
    const formattedValue = this.formatDisplayValue(value);
    const modelValue = this.formatModelValue(value);
    this.formattedValue = formattedValue;
    this.input.nativeElement.value = formattedValue;
    this.model = modelValue;
    if (!suppressChangeValue) {
      this.changeValue.emit(modelValue);
      this.change.emit(this.elRef.nativeElement);
    }
  }

  formatDisplayValue(value): string {
    let displayValue, minDecimalDigit;

    const firstOccurence = value.indexOf('.');

    const currentVal = value.replace(NUMBER_REGEX, (...args) => {
      if (args[0] === '.' && args[1] === firstOccurence) {
        return args[0];
      }
      if (args[0] === '-' && args[1] === 0) {
        return args[0];
      }
      return '';
    });

    minDecimalDigit = currentVal.split('.')[1]?.length || 0;
    minDecimalDigit = Math.min(minDecimalDigit, this.precision);

    if (isNaN(parseFloat(currentVal))) {
      displayValue = currentVal;
    } else {
      displayValue = formatNumber(
        currentVal,
        'en',
        `1.${minDecimalDigit}-${this.precision}`,
      );
      if (currentVal.charAt(currentVal.length - 1) === '.') {
        displayValue = displayValue + '.';
      }
      if (currentVal.charAt(0) === '-' && displayValue.charAt(0) !== '-') {
        displayValue = '-' + displayValue;
      }
    }

    return displayValue;
  }

  formatModelValue(value: string): number | string {
    const currentVal = parseFloat(value.replace(FLOAT_REGEX, ''));
    if (currentVal === 0) {
      return value === '0' ? 0 : value;
    }
    let newVal;

    if (Number.isNaN(currentVal)) {
      return null;
    }

    newVal = currentVal.toFixed(this.precision);
    newVal = parseFloat(newVal);
    return newVal;
  }

  handleFocus(event) {
    this.focus.emit(event);
  }

  handleBlur(event) {
    const eventValue = event.target.value;

    if (eventValue.indexOf('.') > 0 && eventValue.at(eventValue.length - 1) === '0') {
      this.blur.emit(event);
      return;
    }

    this.formatValue(`${this.model}`);
    this.blur.emit(event);
  }
}
